import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FOLDERS } from '../../../../model/Email';
import { ITemplate } from '../../../../model/TemplateData';
import { TemplateDialogData } from '../../../../model/TemplateDialogData';
import { TemplateType } from '../../../../model/TemplateType';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { TemplateService } from '../../../../services/template.service';
import { ConvertedClientsListComponent } from '../../../converted-clients-list/converted-clients-list.component';

@Component({
  selector: 'app-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss'],
})
export class SelectTemplateComponent implements OnInit {
  displayedColumns: string[] = ['title', 'attachment', 'notes', 'salesRepresentative'];
  displayedColumnsReply: string[] = ['titleReply', 'attachmentReply', 'notesReply'];
  replyTemplatesSource: MatTableDataSource<ITemplate>;
  introductionTemplatesSource: MatTableDataSource<ITemplate>;
  isConvertedListLoading = false;

  search = '';
  @Output() closeDialog = new EventEmitter();
  @ViewChild('replyTemplatesSort', { static: false }) replySort: MatSort;
  @ViewChild('introductionTemplatesSort', { static: false }) introductionSort: MatSort;
  templates: ITemplate[] = [];
  public selectedTemplate = null;
  public isLoading = true;
  public showReply = true;
  templateTypes: TemplateType[] = [];

  constructor(
    private templateService: TemplateService,
    @Inject(MAT_DIALOG_DATA) public data: TemplateDialogData,
    public dialog: MatDialog,
    public sdApiService: SdApiService,
  ) {}

  ngOnInit() {
    this.replyTemplatesSource = new MatTableDataSource([]);
    if (this.data.currentFolder !== FOLDERS.SALE_OPERATORS.toLowerCase()) {
      this.showReply = true;
      this.getReplyTemplates();
    } else {
      this.showReply = false;
    }
    this.getIntroductionTemplates();
  }

  onCloseDialog() {
    this.closeDialog.emit();
  }

  selectTemplate(template: ITemplate) {
    this.removeCurrentSelection();
    this.selectedTemplate = template;
    this.selectedTemplate.isSelected = true;
  }

  filterReplyTemplates(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.replyTemplatesSource.filter = filterValue.trim().toLowerCase();
  }

  filterIntroductionTemplates(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.introductionTemplatesSource.filter = filterValue.trim().toLowerCase();
  }

  getReplyTemplates() {
    this.isLoading = true;
    this.templateService.getUseTemplate(1, this.data.sdr).subscribe((response) => {
      var result = this.filterTags(response);
      this.replyTemplatesSource = new MatTableDataSource(
        result.sort((a, b) => {
          if (a.id < b.id) {
            return 1;
          }

          if (a.id > b.id) {
            return -1;
          }

          return 0;
        }),
      );
      this.replyTemplatesSource.sortingDataAccessor = this.handleSortingByTitle;
      this.replyTemplatesSource.sort = this.replySort;
      this.isLoading = false;
    });
  }

  getIntroductionTemplates() {
    this.isLoading = true;
    this.templateService.getUseTemplate(2, this.data.sdr).subscribe((response) => {
      var result = this.filterTags(response);
      this.introductionTemplatesSource = new MatTableDataSource(
        result.sort((a, b) => {
          if (a.id < b.id) {
            return 1;
          }

          if (a.id > b.id) {
            return -1;
          }

          return 0;
        }),
      );
      this.introductionTemplatesSource.sortingDataAccessor = this.handleSortingByTitle;
      this.introductionTemplatesSource.sort = this.introductionSort;
      this.isLoading = false;
    });
  }
  filterTags(response) {
    var result;
    if (this.data.tags === 'LinkedIn') {
      result = response.filter((x) => x.tags === 'LinkedIn');
    } else {
      result = response.filter((x) => x.tags !== 'LinkedIn');
    }
    return result;
  }

  tabChanged(): void {
    this.removeCurrentSelection();
  }

  openCovertedList() {
    this.isConvertedListLoading = true;
    this.sdApiService.getLastClientContact(this.data.client.id).subscribe((response) => {
      this.isConvertedListLoading = false;
      this.dialog.open(ConvertedClientsListComponent, {
        minWidth: '650px',
        data: (response?.data || []).map((contact) => {
          return {
            fullName: contact['Full Name'],
            date: contact['Date'],
            additionalInfo: contact['Additional Info'],
          };
        }),
      });
    });
  }

  private handleSortingByTitle(item, property) {
    switch (property) {
      case 'title':
        return item.title.trim().toLocaleLowerCase();
      default:
        return item[property] || '';
    }
  }

  private removeCurrentSelection() {
    if (!this.selectedTemplate) return;
    this.selectedTemplate.isSelected = false;
    this.selectedTemplate = null;
  }
}
