<div [ngStyle]="{ width: minimized ? '400px ' : '700px' }">
  <app-composer-min-window-bar
    (closeDialog)="close()"
    [minimized]="minimized"
    (toggleMinimize)="toggleMinimize()"
    (expandDialog)="expand()"
  ></app-composer-min-window-bar>
  <mat-dialog-content
    fxLayout="column"
    [style.display]="minimized ? 'none' : ''"
    [ngClass]="'mail-composer-min-content'"
  >
    <app-recipient-fields></app-recipient-fields>
    <div style="padding: 8px">
      <div id="minComposerId"></div>
    </div>
    <div
      class="attachments-wrapper"
      *ngIf="currentComposedEmail?.attachments?.length"
    >
      <div *ngFor="let attachment of currentComposedEmail.attachments">
        <app-attachment
          [attachment]="attachment"
          [isRemovable]="true"
          (clickEvt)="
            messageAttachmentService.downloadDraftFile(
              attachment.filename,
              attachment.isSafe,
              attachment.content,
              attachment.isLocal,
              attachment.key
            )
          "
          (removeEvt)="
            messageAttachmentService.deleteAttachment(
              attachment.filename,
              attachment.fileId,
              attachment.isLocal,
              currentComposedEmail.attachments
            )
          "
        >
        </app-attachment>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    align="start"
    [style.display]="minimized ? 'none' : ''"
  >
    <!-- <div
      *ngIf="currentComposedEmail?.attachments?.length"
      fxLayout="row"
      class="divider"
    ></div> -->

    <button
      mat-flat-button
      color="primary"
      (click)="send()"
      class="send-btn"
      [ngClass]="{ disabledSend: isSdrBlocked || !isSendConfigured || !isReplyReady || !isIntroductionReady }"
      [disabled]="invalidForm || isSdrBlocked || !isSendConfigured"
      id="mail-composer-min-send-btn"
    >
      {{ sendButtonText }}
    </button>
    <div class="attachment-btn">
      <label for="inputFile">
        <span><mat-icon>attach_file</mat-icon></span>
      </label>
      <input
        type="file"
        id="inputFile"
        (change)="addAttachment($event.target.files)"
        multiple
        #inputFileControl
      />
    </div>
    <button
      mat-button
      *ngIf="
        !selectedTemplate &&
        currentComposedEmail.category !== categoryType.New &&
        currentComposedEmail.category !== categoryType.Forward
      "
      (click)="selectTemplate()"
      [disableRipple]="true"
      class="select-template-btn"
      matTooltip="Select Template"
    >
      <mat-icon class="dialog-icons">sticky_note_2</mat-icon>
    </button>
    <p
      *ngIf="selectedTemplate"
      class="templateLabel"
    >
      Template Used:
    </p>
    <p
      *ngIf="selectedTemplate"
      class="templateTitle"
    >
      {{ selectedTemplate.title }}
    </p>
    <button
      mat-icon-button
      *ngIf="currentComposedEmail.category === categoryType.Forward"
      (click)="selectSalesRep()"
      [disableRipple]="true"
      class="select-template-btn"
      matTooltip="Select Sales Representative"
    >
      <mat-icon class="dialog-icons">face</mat-icon>
    </button>
    <button
      mat-icon-button
      *ngIf="displayCopyLeadBtn"
      (click)="onCopyLeadProfile()"
      [disableRipple]="true"
      class="select-template-btn"
      matTooltip="Copy Lead Profile"
      [disabled]="leadCopied || isLoadingLead"
    >
      <mat-icon
        *ngIf="!isLoadingLead"
        class="dialog-icons"
        >group</mat-icon
      >
      <mat-spinner
        class="search-btn-spinner"
        *ngIf="isLoadingLead"
        diameter="20"
      ></mat-spinner>
    </button>
    <button
      mat-icon-button
      *ngIf="displayGetDetailsBtn"
      (click)="onGetConversionDetails()"
      [disableRipple]="true"
      class="select-template-btn"
      matTooltip="Get Conversion Details"
      [disabled]="conversionDetailsPasted || isLoadingConversionDetails"
      id="mail-composer-get-details-btn"
    >
      <mat-icon
        *ngIf="!isLoadingConversionDetails"
        class="dialog-icons"
        >read_more</mat-icon
      >
      <mat-spinner
        class="search-btn-spinner"
        *ngIf="isLoadingConversionDetails"
        diameter="20"
      ></mat-spinner>
    </button>
  </mat-dialog-actions>
</div>
