import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AlertDialogComponent } from "src/app/components/alert-dialog/alert-dialog.component";
import { LoadingStateService } from "src/app/services/base/loading-state.service";
import { SdApiService } from "src/app/services/base/sd-api.service";


const MODAL_DURATION = 3000;

@Component({
    selector: 'app-folder-sdrs-add',
    templateUrl: './folder-sdrs-add.component.html',
    styleUrls: ['./folder-sdrs-add.component.scss'],
  })
  export class FolderSdrsAddComponent implements OnInit, AfterViewInit, OnChanges {
    
    @Input() assignedSdrsList: MatTableDataSource<any>;
    @ViewChild('sdrsSort') sort: MatSort;
    @ViewChild('sdrsPaginator') paginator: MatPaginator;
    @Output() closed = new EventEmitter<void>(); 
    searchText = '';
    availableSdrs = [];
    filteredSdrs = [];
    operatorId = 0;
    sdrCtrl = new FormControl();
    displayedColumns: string[] = ['sdr', 'inbox', 'manual', 'review', 'salesOperations', 'actions'];

    constructor(private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, 
    private service: SdApiService,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar) {}

    ngOnInit(): void {
        var sdr = this.data.sdrs;
        this.operatorId = this.data.operatorId;
        this.assignedSdrsList = new MatTableDataSource<any>(sdr);
    }
    

    ngAfterViewInit(): void {
        this.updateDataSourceAccessors();
      }

    updateDataSourceAccessors() {
        if (!this.assignedSdrsList) return;
        this.assignedSdrsList.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'sdr':
              return item.email;
            default:
              return item[property];
          }
        };
        this.assignedSdrsList.sort = this.sort;
        this.assignedSdrsList.paginator = this.paginator;
        this.assignedSdrsList.filterPredicate = (data, filter) => {
          return this.displayedColumns.some((ele) => {
            return ele === 'sdr' && data['email'].toLowerCase().indexOf(filter) != -1;
          });
        };
      }

      ngOnChanges(changes: SimpleChanges): void {
        if (changes.assignedSdrsList) {
          this.updateDataSourceAccessors();
          this.updateAvailableSdrs();
        }
      }

      updateAvailableSdrs() {
        this.availableSdrs = this.availableSdrs
          .filter((sdr) => !this.assignedSdrsList.data.find((item) => item.sdr === sdr.email))
          .sort();
        this.filteredSdrs = this.availableSdrs;
        this.sdrCtrl.setValue('');
      }

      handleOperationError(message: string) {
        this.loadingStateService.setLoadingState(false);
        const errorConfirmDialogConfig = new MatDialogConfig();
        errorConfirmDialogConfig.data = {
          title: 'Error',
          message,
        };
        this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
      }  

    onRemoveSdr(sdr) {
      this.assignedSdrsList.data.splice(
        this.assignedSdrsList.data.findIndex((item) => item.email === sdr.email),
        1,
      );
      this.assignedSdrsList._updateChangeSubscription();
       
      }

      applySearch() {
        this.assignedSdrsList.filter = this.searchText.trim().toLowerCase();
      }

      isValid() {
        return this.assignedSdrsList.data.length > 0 && 
        this.assignedSdrsList.data.some(item => 
          item.isDefault || 
          item.isManualDiscrepancy || 
          item.isReviewDiscrepancy || 
          item.isSalesOperator
        );
      }

      onClearSearch() {
        this.searchText = '';
        this.applySearch();
      }

      toggleAll(event: MatCheckboxChange, property: string): void {
        const isChecked = event.checked;
        this.assignedSdrsList.data.forEach(element => {
          element[property] = isChecked;
        });
      }
      
      isAllSelected(property: string): boolean {
        return this.assignedSdrsList.data.every(element => element[property]);
      }
      

      saveSdrs(){
        var result = [];
        this.assignedSdrsList.data.map(item => {
          let folders = [];

          if (item.isDefault) {
            folders.push("default");
          }
          if (item.isManualDiscrepancy) {
            folders.push("manual_discrepancies");
          }
          if (item.isReviewDiscrepancy) {
            folders.push("review_discrepancies");
          }
          if (item.isSalesOperator) {
            folders.push("sales_operator");
          }
          result.push({"sdr-id": item.id, "folders": folders})
        });
        this.service.assignUsersFolders(this.operatorId, result).subscribe(
          (response) => {
            this.loadingStateService.setLoadingState(false);
            this.closed.emit();
            this.snackBar.open(`The sdrs was successfully assigned.`, undefined, { duration: MODAL_DURATION });
          },
          (error) => {
            console.log(error);
            this.handleOperationError('An error ocurred while removing the sdr.');
          },
        );
      }
  }