<div
  class="template-editor-container"
  id="layoutDiv"
  fxLayout="column"
  fxLayoutAlign="start center"
>
  <div id="container">
    <div
      id="titleDiv"
      fxLayoutAlign="space-between center"
    >
      <span class="pageTitle">{{ pageTitle }}</span>
      <button
        mat-raised-button
        class="cancel-btn"
        (click)="cancel()"
        id="email-template-editor-cancel-btn"
      >
        Cancel
      </button>
    </div>

    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form"
      fxLayoutAlign="start center"
    >
      <div
        fxFlex.gt-md="48"
        fxFlex.lt-md="100"
        class="margin-type-and-client"
      >
        <mat-form-field
          fxFlex="100"
          class="form-field-5"
          subscriptSizing="dynamic"
          appearance="outline"
          id="email-template-editor-type-mat-form-field"
        >
          <mat-label>Type</mat-label>
          <input
            matInput
            [(ngModel)]="templateTypeLabel"
            disabled
            id="email-template-editor-type-input"
          />
        </mat-form-field>
      </div>
      <div
        fxFlex.gt-md="48"
        fxFlex.lt-md="100"
      >
        <mat-form-field
          fxFlex="100"
          class="form-field-5"
          subscriptSizing="dynamic"
          appearance="outline"
          id="email-template-editor-client-mat-form-field"
        >
          <mat-label>Client</mat-label>
          <input
            type="text"
            aria-label="Number"
            matInput
            required
            [formControl]="clientFilterControl"
            [matAutocomplete]="clientsAuto"
            [(ngModel)]="selectedClient"
            id="email-template-editor-client-input"
          />
          <mat-autocomplete
            #clientsAuto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="updateClients()"
            id="email-template-editor-client-mat-autocomplete"
          >
            <mat-option
              *ngFor="let client of filteredClientOptions | async"
              [value]="client"
              id="email-template-client-mat-option"
            >
              {{ client }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>

    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form"
      fxLayoutAlign="start center"
      [class.hide]="this.templateType === 'reply'"
    >
      <div
        fxFlex.gt-md="48"
        fxFlex.lt-md="100"
        class="margin-row-fifty-percent"
      >
        <mat-form-field
          fxFlex="100"
          class="form-field-5"
          appearance="outline"
          [ngClass]="{
            'field-disabled': !selectedClient,
            'mat-form-field-invalid ng-invalid': !sdrList.length && sdrControl.touched
          }"
          id="email-template-editor-SDRs-mat-form-field"
        >
          <mat-label>SDRs</mat-label>
          <mat-chip-grid #sdrChipList>
            <mat-chip-row
              *ngFor="let item of sdrList; let i = index"
              [color]="item.invalid ? 'warn' : ''"
              selected
              [removable]="removable"
              (removed)="removeSdr(item)"
            >
              {{ item.value }}
              <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip-row>
            <input
              matInput
              [matChipInputFor]="sdrChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addSdr($event)"
              [formControl]="sdrControl"
              [matAutocomplete]="sdrAuto"
              [disabled]="!this.selectedClient"
              #sdrInput
            />
          </mat-chip-grid>
          
          <mat-autocomplete
            #sdrAuto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="updateSdrAuto()"
            id="email-template-editor-SDRs-mat-autocomplete"
          >
            <mat-option
              *ngFor="let sdr of filteredSdrs | async"
              [value]="sdr"
              id="email-template-editor-SDRs-mat-option"
            >
              {{ sdr }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div
        fxFlex.gt-md="48"
        fxFlex.lt-md="100"
      >
        <mat-form-field
          fxFlex="100"
          class="form-field-5"
          appearance="outline"
          [ngClass]="{
            'field-disabled': !selectedClient,
            'mat-form-field-invalid ng-invalid': !salesRepList.length && salesRepControl.touched
          }"
          id="email-template-editor-sales-rep-mat-form-field"
        >
          <mat-label>Sales rep.</mat-label>
          <mat-chip-grid #salesRepChipList>
            <mat-chip-row
              [color]="item.invalid ? 'warn' : ''"
              selected
              *ngFor="let item of salesRepList; let i = index"
              [removable]="removable"
              (removed)="removeSalesRep(item)"
              name="salesRepChips"
              id="email-template-editor-sales-rep-mat-chip-row"
              >{{ item.value }}
              <mat-icon
                matChipRemove
                *ngIf="removable"
                id="email-template-editor-sales-rep-mat-icon-remove"
                >cancel</mat-icon
              >
            </mat-chip-row>
            <input
              matInput
              [matChipInputFor]="salesRepChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addSalesRep($event)"
              [formControl]="salesRepControl"
              [matAutocomplete]="salesRepAuto"
              [disabled]="!this.selectedClient"
              #salesRepInput
              id="email-template-editor-sales-rep-input"
            />
          </mat-chip-grid>
          <mat-autocomplete
            #salesRepAuto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="updateSalesRepAuto()"
            id="email-template-editor-sales-rep-mat-autocomplete"
          >
            <mat-option
              *ngFor="let salesRep of filteredSalesReps | async"
              [value]="salesRep"
              id="email-template-editor-sales-rep-mat-option"
            >
              {{ salesRep }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        fxFlex="100"
        class="form-field-5"
        appearance="outline"
        subscriptSizing="dynamic"
        id="email-template-editor-title-mat-form-field"
      >
        <mat-label>Title</mat-label>
        <input
          matInput
          [(ngModel)]="title"
          required
          maxlength="50"
          id="email-template-editor-title-input"
        />
      </mat-form-field>
    </div>

    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form hide"
      fxLayoutAlign="start center"
    >
      <div
        fxFlex="48"
        class="margin-row-fifty-percent"
      >
        <label class="title-input">CC</label>
        <mat-form-field
          class="dense-input"
          appearance="fill"
          id="email-template-editor-CC-mat-form-field"
        >
          <mat-chip-grid #ccChipList>
            <mat-chip-row
              [color]="item.invalid ? 'warn' : ''"
              selected
              *ngFor="let item of ccList; let i = index"
              [removable]="removable"
              (removed)="removeCC(item)"
              name="ccChips"
              id="email-template-editor-CC-mat-chip-row"
              >{{ item.value }}
              <mat-icon
                matChipRemove
                *ngIf="removable"
                id="email-template-editor-CC-mat-icon-remove"
                >cancel</mat-icon
              >
            </mat-chip-row>
            <input
              matInput
              [matChipInputFor]="ccChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addCC($event)"
              [formControl]="ccControl"
              #ccInput
              id="email-template-editor-CC-input"
            />
          </mat-chip-grid>
        </mat-form-field>
      </div>
      <div fxFlex="48">
        <label class="title-input">BCC</label>
        <mat-form-field
          class="dense-input"
          appearance="fill"
          id="email-template-editor-BCC-mat-form-field"
        >
          <mat-chip-grid #bccChipList>
            <mat-chip-row
              [color]="item.invalid ? 'warn' : ''"
              selected
              *ngFor="let item of bccList; let i = index"
              [removable]="removable"
              (removed)="removeBCC(item)"
              name="bccChips"
              id="email-template-editor-BCC-mat-chip-row"
              >{{ item.value }}
              <mat-icon
                matChipRemove
                *ngIf="removable"
                id="email-template-editor-BCC-mat-icon-remove"
                >cancel</mat-icon
              >
            </mat-chip-row>
            <input
              matInput
              [matChipInputFor]="bccChipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addBCC($event)"
              [formControl]="bccControl"
              #bccInput
              id="email-template-editor-BCC-input"
            />
          </mat-chip-grid>
        </mat-form-field>
      </div>
    </div>

    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form"
      fxLayoutAlign="start center"
    >
      <div fxFlex="100">
        <div>
          <div id="editorId"></div>
        </div>
      </div>
    </div>

    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form"
      fxLayoutAlign="start center"
    >
      <label
        fxFlex="100"
        class="title-input"
        >Attachments</label
      >
      <div
        fxFlex.gt-md="30"
        fxFlex.lt-md="40"
      >
        <div class="attachment">
          <label
            for="email-template-editor-attachment-input"
            class="attachment-label"
          >
            <div
              class="actionPointer"
              fxLayout="row"
              fxLayoutAlign="center center"
            >
              BROWSE FILES
            </div>
          </label>
          <input
            type="file"
            class="attachment-input"
            (change)="attach($event.target.files)"
            multiple
            #inputFileControl
            id="email-template-editor-attachment-input"
          />
        </div>
      </div>
      <div fxFlex="70">
        <!-- Attach progress bar -->
        <div
          *ngIf="attachments?.length > 0"
          class="attachment"
          fxLayout="row wrap"
          fxFlexFill
          fxLayoutAlign="start start"
        >
          <div
            *ngFor="let attachment of attachments"
            class="attachment-progress"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
          >
            <div
              *ngIf="attachment.isUploading"
              class="attachment-progress-text"
              fxLayout="row wrap"
              fxLayoutAlign="start center"
            >
              {{ messageAttachmentService.getFileName(attachment.filename) | truncate: 15 : false : '..'
              }}{{ messageAttachmentService.getExtension(attachment.filename) }}
              <mat-progress-bar
                mode="indeterminate"
                class="attachment-progress-bar"
              ></mat-progress-bar>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="100">
        <!-- Attachments -->
        <div
          *ngIf="attachments?.length > 0"
          class="attachment uploaded-files"
          fxFlex="row"
          fxFlexFill
          fxLayoutAlign="start start"
          id="readAttachmentsDiv"
        >
          <div
            *ngFor="let attachment of attachments"
            fxLayout="row wrap"
            fxLayoutAlign="start center"
          >
            <div
              *ngIf="!attachment.isUploading"
              class="uploaded-file"
              fxLayout="row"
              fxLayoutAlign="start center"
            >
              <a
                class="uploaded-file-text"
                (click)="messageAttachmentService.downloadRemoteFileOld(attachment.filename, attachment.key,attachment.isSafe)"
                [style.cursor]="attachment.isSafe ? 'pointer' : 'initial'"
                id="email-template-editor-download-remote-file-anchor"
              >
                <div
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  class="attachment-box"
                  matTooltip="{{ attachment.filename }}"
                >
                  <span class="attachment-name">
                    {{ messageAttachmentService.getFileName(attachment.filename) | truncate: 15 : false : '..'
                    }}{{ messageAttachmentService.getExtension(attachment.filename) }}
                  </span>
                  <span class="attachment-size"> {{ messageAttachmentService.getKbSize(attachment.size) }} kb </span>
                </div>
              </a>
              <a
                class="actionPointer"
                (click)="
                  messageAttachmentService.deleteAttachment(
                    attachment.filename,
                    attachment.fileId,
                    attachment.isLocal,
                    attachments
                  )
                "
                id="email-template-editor-delete-attachment-file-anchor"
              >
                <mat-icon>close</mat-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        fxFlex="100"
        class="form-field-5"
        appearance="outline"
        subscriptSizing="dynamic"
        id="email-template-editor-notes-mat-form-field"
      >
        <mat-label>Notes</mat-label>
        <textarea
          fxFlex="100"
          matInput
          [(ngModel)]="notes"
          style="resize: none"
          maxlength="1000"
          id="email-template-editor-notes-text-area"
        ></textarea>
      </mat-form-field>
    </div>

    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        fxFlex="100"
        class="form-field-5"
        appearance="outline"
        id="email-template-editor-tags-mat-form-field"
      >
        <mat-label>Tags</mat-label>
        <mat-chip-grid #tagChipList>
          <mat-chip-row
            [color]="item.invalid ? 'warn' : ''"
            *ngFor="let item of tagList; let i = index"
            [removable]="removable"
            (removed)="removeTag(item)"
            name="tagChips"
            id="email-template-editr-tags-mat-chip-row"
            >{{ item.value }}
            <mat-icon
              matChipRemove
              *ngIf="removable"
              id="email-template-editor-tags-mat-icon-remove"
              >cancel</mat-icon
            >
          </mat-chip-row>
          <input
            matInput
            [matChipInputFor]="tagChipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addTag($event)"
            [formControl]="tagControl"
            #tagInput
            id="email-template-editor-tags-input"
          />
        </mat-chip-grid>
      </mat-form-field>
    </div>

    <div
      fxLayout="row wrap"
      class="create-introduction-template-row-form"
      fxLayoutAlign="start center"
      [class.hide]="this.templateType === 'reply'"
    >
      <div fxFlex="100">
        <mat-slide-toggle
          color="primary"
          [(ngModel)]="isInternalUse"
          [color]="'Primary'"
          id="email-template-editor-internal-use-mat-slide-toggle"
          >Internal Use Only</mat-slide-toggle
        >
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      class="create-introduction-template-row-form btnCreateTemplate-row"
    >
      <button
        (click)="createTemplate()"
        class="btnCreateTemplate"
        mat-raised-button
        color="primary"
        [disabled]="invalidForm || isSubmitting"
        id="email-template-editor-create-template-btn"
      >
        {{ submitTemplateLabel }}
      </button>
    </div>
  </div>
</div>
