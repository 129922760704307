<div class="details-toolbar">
  <div
    class="details-toolbar__container"
    *ngIf="currentEmail"
  >
    <div class="details-toolbar__header">
      <div class="details-toolbar__subject">
        <span
          [matTooltip]="currentEmail?.subject"
          matTooltipPosition="below"
          id="email-subject"
          >{{ currentEmail?.subject }}</span
        >
      </div>

      <div class="details-toolbar__actions">
        <button
          *ngIf="currentEmail"
          mat-stroked-button
          color="primary"
          (click)="openPlainText()"
          matTooltip="Open Plain Text"
          id="open-plain-btn"
          class="details-toolbar__action-btn"
        >
          <mat-icon class="material-icons-outlined">description</mat-icon>
        </button>
        <button
          *ngIf="currentEmail"
          mat-stroked-button
          color="primary"
          (click)="openFaq()"
          matTooltip="Open Faq"
          id="open-faq-btn"
          class="details-toolbar__action-btn"
        >
          <mat-icon>help_outline</mat-icon>
        </button>
        <button
          *ngIf="currentEmail"
          mat-stroked-button
          color="primary"
          (click)="copyDebug()"
          matTooltip="Clipboard Debug"
          id="copy-debug-btn"
          class="details-toolbar__action-btn"
        >
          <mat-icon class="material-icons-outlined">bug_report</mat-icon>
        </button>
        <button
          *ngIf="currentEmail && isSender"
          mat-stroked-button
          color="primary"
          (click)="compose('replyAll')"
          matTooltip="Reply All"
          [disabled]="isComposerOpen"
          id="reply-all-btn"
          class="details-toolbar__action-btn"
        >
          <mat-icon>reply_all</mat-icon>
        </button>
        <button
          *ngIf="currentEmail && isSender"
          mat-stroked-button
          color="primary"
          (click)="compose('forward')"
          matTooltip="Forward"
          [disabled]="isComposerOpen"
          id="forward-btn"
          class="details-toolbar__action-btn"
        >
          <mat-icon>arrow_forward</mat-icon>
        </button>
        <button
          *ngIf="currentEmail && mailTo.length > 0"
          mat-stroked-button
          color="primary"
          (click)="openMailTo()"
          matTooltip="Inspect Mailto"
          id="inspect-mail-to-btn"
          class="details-toolbar__action-btn"
        >
          <mat-icon class="material-icons-outlined">remove_red_eye</mat-icon>
        </button>
      </div>
    </div>

    <div class="details-toolbar__sender-recipients-details">
      <app-mail-sender-recipients-details [email]="currentEmail"></app-mail-sender-recipients-details>
    </div>
  </div>
</div>
