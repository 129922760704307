<div
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="div-template"
>
  <div class="title">Selected Sdrs</div>
  <div fxLayout="row">
    <mat-icon
      class="icon-button"
      mat-dialog-close
      >close</mat-icon
    >
  </div>
</div>
<div class="folders-sdrs-list-container">
<div class="mat-elevation-z8 shrink">
    <mat-form-field
      appearance="outline"
      class="form-field-5 search-sdr-field"
      subscriptSizing="dynamic"
      id="folders-sdrs-search-sdr-mat-form-field"
    >
      <mat-label>Search SDR</mat-label>
      <input
        matInput
        [(ngModel)]="searchText"
        autocomplete="off"
        (keyup)="applySearch()"
        id="folders-sdrs-search-sdr-input"
      />
      <mat-icon
        matSuffix
        *ngIf="searchText"
        (click)="onClearSearch()"
        id="folders-sdrs-search-sdr-mat-icon-clear"
        >close</mat-icon
      >
    </mat-form-field>
    <mat-table
      [dataSource]="assignedSdrsList"
      matSort
      matSortActive="sdr"
      matSortDirection="asc"
      matSortDisableClear
      #sdrsSort="matSort"
    >
      <ng-container matColumnDef="sdr" class="sdr-column">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          >SDR</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="inbox">
        <mat-header-cell
          *matHeaderCellDef
          class="center header-checkbox"
          ><div>Inbox
          <mat-checkbox (change)="toggleAll($event, 'isDefault')" [checked]="isAllSelected('isDefault')"></mat-checkbox></div>
          </mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="center">
          <mat-checkbox
          [(ngModel)]="element.isDefault"
          color="primary">
          {{ element?.inbox }}
        </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="manual">
        <mat-header-cell
          *matHeaderCellDef
          class="center header-checkbox"
          ><div>
          Manual
          <mat-checkbox (change)="toggleAll($event, 'isManualDiscrepancy')" [checked]="isAllSelected('isManualDiscrepancy')">
          </mat-checkbox></div>
          </mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="center">
          <mat-checkbox
          [(ngModel)]="element.isManualDiscrepancy"
          color="primary">
          {{ element?.manual }}
        </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="review">
        <mat-header-cell
          *matHeaderCellDef
          class="center header-checkbox"
          ><div>Review
          <mat-checkbox (change)="toggleAll($event, 'isReviewDiscrepancy')" [checked]="isAllSelected('isReviewDiscrepancy')">
          </mat-checkbox></div>
          </mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="center">
          <mat-checkbox
          [(ngModel)]="element.isReviewDiscrepancy"
          color="primary">
          {{ element?.review }}
        </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="salesOperations">
        <mat-header-cell
          *matHeaderCellDef
          class="center header-checkbox"
          ><div>Sales Operations
          <mat-checkbox (change)="toggleAll($event, 'isSalesOperator')" [checked]="isAllSelected('isSalesOperator')">
          </mat-checkbox></div>
          </mat-header-cell
        >
        <mat-cell *matCellDef="let element" class="center">
          <mat-checkbox
          [(ngModel)]="element.isSalesOperator"
          color="primary">
          {{ element?.sales_operator }}
        </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell
          *matHeaderCellDef
          class="center"
          >Actions</mat-header-cell
        >
        <mat-cell
          *matCellDef="let row"
          class="center"
        >
          <button
            mat-icon-button
            (click)="onRemoveSdr(row)"
            id="folders-sdrs-remove-sdr-btn"
          >
            <mat-icon>remove_circle</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="loading">
        <mat-footer-cell
          *matFooterCellDef
          colspan="2"
        >
          Loading data...
        </mat-footer-cell>
      </ng-container>
      <ng-container matColumnDef="noData">
        <mat-footer-cell
          *matFooterCellDef
          colspan="2"
        >
          No data.
        </mat-footer-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      <mat-footer-row
        *matFooterRowDef="['loading']"
        [ngClass]="{ hide: assignedSdrsList !== null }"
      ></mat-footer-row>
      <mat-footer-row
        *matFooterRowDef="['noData']"
        [ngClass]="{ hide: !(assignedSdrsList !== null && assignedSdrsList.data.length === 0) }"
      ></mat-footer-row>
    </mat-table>
    <mat-paginator
      [pageSize]="10"
      showFirstLastButtons
      #sdrsPaginator="matPaginator"
    ></mat-paginator>
    <mat-dialog-actions align="end">
        <button
          mat-raised-button
          color="primary"
          (click)="saveSdrs()"
          [disabled]="!isValid()"
          [mat-dialog-close]="assignedSdrsList"
          style="box-shadow: none"
          class="buttom-template"
          id="assign-sdr-and-folders"
        >
          SAVE
        </button>
      </mat-dialog-actions>
  </div>
 
</div>