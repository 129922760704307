import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingStateService } from 'src/app/services/base/loading-state.service';
import { AlertDialogComponent } from 'src/app/components/alert-dialog/alert-dialog.component';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MessageAttachmentService } from 'src/app/services/message-attachment.service';
import { SDAuthService } from 'src/app/services/sd-auth.service';
import { Location } from '@angular/common';
import { ContactsDataService } from 'src/app/services/contacts-data.service';
import { CustomerService } from 'src/app/services/customer.service';

const MODAL_DURATION = 3000;

@Component({
  selector: 'app-client-contacts-edit',
  templateUrl: './client-contacts-edit.component.html',
  styleUrls: ['./client-contacts-edit.component.scss']
})
export class ClientContactsEditComponent implements OnInit {

  constructor(
    private service: SdApiService,
    private router: Router,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    public messageAttachmentService: MessageAttachmentService,
    private sdApiService: SdApiService,
    private authService: SDAuthService,
    private location: Location,
    private contactsDataService : ContactsDataService,
    private customerService: CustomerService
  ) {}

  pageTitle = '';
  submitContactLabel = '';
  loading = false;
  mode = '';
  contactId = 0;
  name = '';
  comments = '';
  geographicZone = '';
  email = '';
  jobPosition = '';
  selectedStatus = '';
  updatedDt = '';
  userEmail = '';
  userCreated = '';
  userModified = '';
  clientEditValue;
  selectedClient: string = null;
  isSubmitting = false;
  emailPatternExpression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  statusOptions = ['Active', 'Suspended'];

  public profileCcList = [];
  profileCcControl = new UntypedFormControl('', []);

  public emailTemplateCcList = [];
  emailTemplateCcControl = new UntypedFormControl('', []);

  public emailTemplateBccList = [];
  emailTemplateBccControl = new UntypedFormControl('', []);

  filteredClientOptions: Observable<any[]>;

  public clientFilterControl = new UntypedFormControl();
  availableClients: string[] = [];
  allClientsObj = [];

  removable = true;

  separatorKeyCodes = [ENTER, COMMA];

  @ViewChild('profileCcInput') profileCcInput: ElementRef;
  @ViewChild('emailTemplateCcInput') emailTemplateCcInput: ElementRef;
  @ViewChild('emailTemplateBccInput') emailTemplateBccInput: ElementRef;

  ngOnInit() {
    this.loadingStateService.isLoading.subscribe(loading => {
      this.loading = loading;
    });

    this.activatedRoute.queryParams.subscribe(async params => {
      this.contactId = params.id;
      this.mode = this.contactId == 0 ? 'new' : 'edit';

      this.userEmail = await this.authService.getCurrentUserEmail();

      if (this.mode === 'edit') {
        // Load the contact
        this.loadingStateService.setLoadingState(true);
        this.contactsDataService.loadContact(this.contactId).subscribe(contactData => {
          this.loadingStateService.setLoadingState(false);
          this.setLabels();

          // this.userCreated = contactData.UserCreated;
          this.userModified = this.userEmail;
          this.name = contactData.name;
          this.comments = contactData.comments;
          this.geographicZone = contactData.geographic_zone;
          this.email = contactData.email;
          this.jobPosition = contactData.job_position;
          this.selectedStatus = contactData.status;
          // this.updatedDt = contactData.updatedDt;

          if (this.allClientsObj.length) {
            const clientEquivalent = this.allClientsObj.find(c => c.id === contactData.client.id);
            this.selectedClient = clientEquivalent?.name || '';
          } else {
            this.clientEditValue = contactData.client.id;
          }

          if (contactData.tempProfileCC) {
            contactData.tempProfileCC.split(';').forEach(profileCc => {
              this.profileCcList.push({ value: profileCc.trim(), invalid: false });
            });
          }
          if (contactData.tempEmailTemplateCC) {
            contactData.tempEmailTemplateCC.split(';').forEach(profileBcc => {
              this.emailTemplateCcList.push({ value: profileBcc, invalid: false });
            });
          }

          if (contactData.tempEmailTemplateBCC) {
            contactData.tempEmailTemplateBCC.split(';').forEach(emailTemplateBcc => {
              this.emailTemplateBccList.push({ value: emailTemplateBcc, invalid: false });
            });
          }
        });
      } else {
        this.setLabels();
        this.userCreated = this.userEmail;
        this.userModified = '';
      }
    });

    this.loadingStateService.setLoadingState(true);
    this.customerService.getCustomers().subscribe(response => {
      this.loadingStateService.setLoadingState(false);
      const clients = response.data;
      this.availableClients = clients.map(c => c.customer_name).sort();
      this.allClientsObj = clients.map(c => {
        c.id = c.customer_id;
        c.name = c.customer_name;
        return c;
      });
      this.filteredClientOptions = this.clientFilterControl.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.customer_name),
          map(customerName => customerName ? this._filterClient(customerName) : this.availableClients.slice())
        );
      
      if (this.mode === 'edit') {
        if (this.clientEditValue) {
          const clientEquivalent = this.allClientsObj.find(c => c.id === this.clientEditValue);
          this.selectedClient = clientEquivalent?.name || '';
        }
      }
    });
  }

  setLabels() {
    if (this.mode === 'new') {
      this.pageTitle += 'Create Contact';
      this.submitContactLabel = "CREATE CONTACT";
    } else {
      this.pageTitle += 'Edit Contact';
      this.submitContactLabel = 'SAVE';
    }
  }

  private _filterClient(client: string): string[] {
    const filterValue = client.toLowerCase();

    return this.availableClients.filter(e => e.toLowerCase().includes(filterValue));
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  addProfileCc(event): void {
    event.value = event.value.trim();
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.profileCcList.push({ value: event.value, invalid: false });
      } else {
        this.profileCcList.push({ value: event.value, invalid: true });
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeProfileCc(data: any): void {
    if (this.profileCcList.indexOf(data) >= 0) {
      this.profileCcList.splice(this.profileCcList.indexOf(data), 1);
    }
  }

  addEmailTemplateCc(event): void {
    event.value = event.value.trim();
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.emailTemplateCcList.push({ value: event.value, invalid: false });
      } else {
        this.emailTemplateCcList.push({ value: event.value, invalid: true });
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  addEmailTemplateBcc(event): void {
    event.value = event.value.trim();
    if (event.value) {
      if (this.validateEmail(event.value)) {
        this.emailTemplateBccList.push({ value: event.value, invalid: false });
      } else {
        this.emailTemplateBccList.push({ value: event.value, invalid: true });
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  removeEmailTemplateCc(data: any): void {
    if (this.emailTemplateCcList.indexOf(data) >= 0) {
      this.emailTemplateCcList.splice(this.emailTemplateCcList.indexOf(data), 1);
    }
  }

  removeEmailTemplateBcc(data: any): void {
    if (this.emailTemplateBccList.indexOf(data) >= 0) {
      this.emailTemplateBccList.splice(this.emailTemplateBccList.indexOf(data), 1);
    }
  }

  displayFn(input: string): string {
    return input ? input : '';
  }

  private validateEmail(email) {
    return this.emailPatternExpression.test(String(email).toLowerCase());
  }

  get invalidForm(): boolean {
    if (!this.name || !this.email || !this.selectedClient || !this.availableClients.includes(this.selectedClient) ||
      this.profileCcList.some(cc => cc.invalid) ||
      this.emailTemplateCcList.some(cc => cc.invalid) ||
      this.emailTemplateBccList.some(cc => cc.invalid) ||
      !this.validateEmail(this.email)) {
      return true;
    }
    return false;
  }

  createContact() {
    const clientId = this.allClientsObj.filter(c => c.name === this.selectedClient)[0].id;
    this.isSubmitting = true;

    this.contactsDataService.createOrUpdateContact(this.mode, this.contactId, this.name, clientId, this.comments, this.geographicZone, this.email,
      this.jobPosition, this.profileCcList.map(cc => cc.value).join(';'), this.emailTemplateCcList.map(cc => cc.value).join(';'),
      this.emailTemplateBccList.map(bcc => bcc.value).join(';'), this.userCreated, this.userModified, this.selectedStatus).subscribe(res => {
        if (res.status === 200 || res.status === 204 || res.status === 'success') {
          const operationSuccessLabel = this.mode === 'new' ? ' created ' : ' edited ';
          this.snackBar.open(`Contact ${operationSuccessLabel} successfully`, null, {
            duration: MODAL_DURATION,
          });
          this.router.navigate(['/home/management/contacts/contact-manage']);
        } else {
          const operationFailureLabel = this.mode === 'new' ? ' creating ' : ' editing ';
          this.snackBar.open(`An error ocurred while ${operationFailureLabel} the contact`, null, {
            duration: MODAL_DURATION,
          });
          this.isSubmitting = false;
        }
      });

  }

  cancel() {
    this.location.back();
  }
}

