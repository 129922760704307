import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { unionBy } from 'lodash';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { environment } from '../../../environments/environment';
import { Attachment } from '../../model/Attachment';
import { IAttachment } from '../../model/attachment.model';
import { ComposeEmail } from '../../model/ComposeEmail';
import { ComposeEmailCategory } from '../../model/ComposeEmailCategory';
import { PRODUCT_TAG } from '../../model/Config';
import { Email, FOLDERS } from '../../model/Email';
import { IInquiryLead, InquiryLead } from '../../model/InquiryLead';
import { IMailClassification, MailCategory } from '../../model/MailCategory';
import { Permission } from '../../model/Permission';
import { ClientsDataContext, Sdr } from '../../model/Sdr';
import { ITemplateDeleteResponse, Template } from '../../model/TemplateData';
import { User } from '../../model/User';
import { AppConstants } from '../../resources/app-constants';
import { SDAuthService } from '../../services/sd-auth.service';
import { LoadingStateService } from './loading-state.service';

interface ISaveSdrPayload {
  user_id: number;
  operator_id: number;
  sdr_type: string;
}
const PAGE_SIZE = 50;
@Injectable({
  providedIn: 'root',
})
export class SdApiService {
  public sdrs = new BehaviorSubject<Sdr[]>([]);
  private sdrsReview = new BehaviorSubject<Sdr[]>([]);
  private sdrsManual = new BehaviorSubject<Sdr[]>([]);
  private sdrsSent = new BehaviorSubject<Sdr[]>([]);
  private sdrsSales = new BehaviorSubject<Sdr[]>([]);
  private sdrsAll = new BehaviorSubject<Sdr[]>([]);
  public blockedSdrs = new BehaviorSubject<string[]>([]);
  private clients = new BehaviorSubject<Array<any>>([]);
  private clientsReview = new BehaviorSubject<Array<any>>([]);
  private clientsManual = new BehaviorSubject<Array<any>>([]);
  private clientsSent = new BehaviorSubject<Array<any>>([]);
  private clientsSales = new BehaviorSubject<Array<any>>([]);
  private clientsAll = new BehaviorSubject<Array<any>>([]);
  private folders = new BehaviorSubject<string[]>([]);
  private sdrClassifications = new BehaviorSubject<IMailClassification[]>([]);
  private clientsDataContext = new BehaviorSubject<ClientsDataContext>(ClientsDataContext.HOMEPAGE);
  private permissions$: Observable<any> | null = null;
  private operationsSegment = '/classification';
  private managementSegment = '/management';
  private sendMailSegment = '/sendmail';
  private documentSegment = '/documents';
  private templateSegment = '/app/template';
  private userSegment = '/app/user';
  private feedSegment = '/classification-history/classification';

  constructor(
    private httpClient: HttpClient,
    private loadingState: LoadingStateService,
    private authService: SDAuthService,
  ) {}

  get userSDRs(): Observable<Sdr[]> {
    return this.sdrs.asObservable();
  }

  get userSdrsReview(): Observable<Sdr[]> {
    return this.sdrsReview.asObservable();
  }

  get userSdrsManual(): Observable<Sdr[]> {
    return this.sdrsManual.asObservable();
  }

  get userSdrsSales(): Observable<Sdr[]> {
    return this.sdrsSales.asObservable();
  }

  get userSdrsSent(): Observable<Sdr[]> {
    return this.sdrsSent.asObservable();
  }

  get userClients(): Observable<any[]> {
    return this.clients.asObservable();
  }

  get userClientsReview(): Observable<any[]> {
    return this.clientsReview.asObservable();
  }

  get userClientsManual(): Observable<any[]> {
    return this.clientsManual.asObservable();
  }

  get userClientsSales(): Observable<any[]> {
    return this.clientsSales.asObservable();
  }

  get userClientsSent(): Observable<any[]> {
    return this.clientsSent.asObservable();
  }

  get userFolders() {
    return this.folders.asObservable();
  }

  get userBlockedSdrs() {
    return this.blockedSdrs.asObservable();
  }

  get classifications() {
    return this.sdrClassifications.asObservable();
  }

  get userClientsAll(): Observable<any[]> {
    return this.clientsAll.asObservable();
  }

  setClientsContext(context: ClientsDataContext) {
    this.clientsDataContext.next(context);
  }

  loadSingleMailSent(sdr: string[], folder: string, pageNumber: number): Observable<Email[]> {
    if (!folder || !sdr) {
      return of([]);
    }
    this.sendLog({
      tags: [PRODUCT_TAG, sdr, folder, 'GetSdrFeed', 'POST'],
      body: '',
    }).subscribe();
    this.loadingState.setLoadingState(true);
    this.loadingState.setFeedLoadingState(true);
    let endpoint = '';
    endpoint = environment.newApiBaseUrl + this.feedSegment + '/get-sdr-feed/sent';
    const body = {
      sdr,
      current_folder: folder,
      page: pageNumber,
      pageSize: PAGE_SIZE,
    };
    return this.post(endpoint, body).pipe(
      map((response: any) => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
        this.sendLog({
          tags: [PRODUCT_TAG, sdr, folder, 'GetSdrFeed', 'SUCCESS'],
          body: '',
        }).subscribe();
        const feed: any[] = response;
        return feed
          .filter((m) => m?.id)
          .map((mailData: any) => {
            mailData.folder = folder;
            if (mailData.attachments) {
              mailData.attachments = mailData.attachments.map((attachment: IAttachment) => {
                return new Attachment({
                  filename: attachment.name,
                  key: attachment.path,
                  isSafe: attachment.isSafe,
                  fileId: 'TODO discard',
                  size: attachment.size,
                  isLocal: false,
                  contentType: attachment.contentType,
                  contentId: attachment.contentId,
                });
              });
            }

            return new Email(mailData);
          });
      }),
      catchError((error) => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
        this.sendLog({
          tags: [PRODUCT_TAG, sdr, folder, 'GetSdrFeed', 'ERROR'],
          body: error,
        }).subscribe();
        console.log(error);
        return of([]);
      }),
    );
  }

  loadSingleMailFeed(sdr: string[], folder: string, pageNumber: number = 1): Observable<Email[]> {
    if (!folder || !sdr) {
      return of([]);
    }
    this.sendLog({
      tags: [PRODUCT_TAG, sdr, folder, 'GetSdrFeed', 'POST'],
      body: '',
    }).subscribe();
    this.loadingState.setLoadingState(true);
    this.loadingState.setFeedLoadingState(true);
    let endpoint = environment.newApiBaseUrl + this.feedSegment + '/get-sdr-feed';
    var body = {};
    if (folder != FOLDERS.CONVERTED) {
      body = {
        sdr,
        current_folder: folder,
      };
    } else {
      body = {
        sdr,
        current_folder: folder,
        page: pageNumber,
        pageSize: PAGE_SIZE,
      };
    }
    return this.post(endpoint, body).pipe(
      map((response: any) => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
        this.sendLog({
          tags: [PRODUCT_TAG, sdr, folder, 'GetSdrFeed', 'SUCCESS'],
          body: '',
        }).subscribe();
        const feed: any[] = response;
        return feed
          .filter((m) => m?.id)
          .map((mailData: any) => {
            mailData.folder = folder;
            if (mailData.attachments) {
              mailData.attachments = mailData.attachments.map((attachment: IAttachment) => {
                return new Attachment({
                  filename: attachment.name,
                  key: attachment.path,
                  isSafe: attachment.isSafe,
                  fileId: 'TODO discard',
                  size: attachment.size,
                  isLocal: false,
                  contentType: attachment.contentType,
                  contentId: attachment.contentId,
                });
              });
            }

            return new Email(mailData);
          });
      }),
      catchError((error) => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
        this.sendLog({
          tags: [PRODUCT_TAG, sdr, folder, 'GetSdrFeed', 'ERROR'],
          body: error,
        }).subscribe();
        console.log(error);
        return of([]);
      }),
    );
  }

  loadSingleMailFeed2(sdrs: string[], folder: string, pageNumber: number = 1): Observable<Email[]> {
    if (!folder || !sdrs || sdrs.length === 0) {
      return of([]);
    }
    this.sendLog({
      tags: [PRODUCT_TAG, sdrs, folder, 'GetSdrFeed', 'POST'],
      body: '',
    }).subscribe();
    this.loadingState.setLoadingState(true);
    this.loadingState.setFeedLoadingState(true);
    let endpoint = environment.newApiBaseUrl + this.feedSegment + '/get-sdr-feed';
    var body = {};
    if (folder != FOLDERS.CONVERTED) {
      body = {
        sdr: sdrs,
        current_folder: folder,
      };
    } else {
      body = {
        sdr: sdrs,
        current_folder: folder,
        page: pageNumber,
        pageSize: PAGE_SIZE,
      };
    }
    return this.post(endpoint, body).pipe(
      map((response: any) => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
        this.sendLog({
          tags: [PRODUCT_TAG, sdrs, folder, 'GetSdrFeed', 'SUCCESS'],
          body: '',
        }).subscribe();
        const feed: any[] = response;
        return feed.map((mailData: any) => {
          mailData.folder = folder;
          if (mailData.attachments) {
            mailData.attachments = mailData.attachments.map((attachment: IAttachment) => {
              return new Attachment({
                filename: attachment.name,
                key: attachment.path,
                isSafe: attachment.isSafe,
                fileId: 'TODO discard',
                size: attachment.size,
                isLocal: false,
                contentId: attachment.contentId,
              });
            });
          }
          return new Email(mailData);
        });
      }),
      catchError((error) => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
        this.sendLog({
          tags: [PRODUCT_TAG, sdrs, folder, 'GetSdrFeed', 'ERROR'],
          body: error,
        }).subscribe();
        console.log(error);
        return of([]);
      }),
    );
  }

  loadContactEmailsByClients(clients: string[]): Observable<string[]> {
    this.loadingState.setLoadingState(true);
    this.loadingState.setFeedLoadingState(true);
    const endpoint = `${environment.newApiBaseUrl}/configuration/getContactEmailsByClients`;
    const body = { clients };
  
    return this.post(endpoint, clients).pipe(
      finalize(() => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
      }),
      map((response: any) => response.status === 'success' ? response.data : [])
    );
  }

  loadFolders(){
    const endpoint = `${environment.newApiBaseUrl}/configuration/getFolders`;
    this.httpClient
      .get(endpoint)
      .pipe(
        finalize(() => {
          this.loadingState.setLoadingState(false);
          this.loadingState.setFeedLoadingState(false);
        }),
      )
      .subscribe((response: any) => {
        const folders = response.data;
        this.folders.next(folders);
      });

  }

  loadUserClientsSDRs() {
    this.loadingState.setLoadingState(true);
    this.loadingState.setFeedLoadingState(true);
    const endpoint = `${environment.newApiBaseUrl}/configuration/getClientsSdr`;
    this.sendLog({
      tags: [PRODUCT_TAG, 'loadUserClientsSDRs', 'GET'],
      body: '',
    }).subscribe();
    this.httpClient
      .get(endpoint)
      .pipe(
        finalize(() => {
          this.loadingState.setLoadingState(false);
          this.loadingState.setFeedLoadingState(false);
        }),
      )
      .subscribe((response: any) => {
        const mappedClientsData = this.mapClientsAndSdrs(response.data.clients.slice());

        this.clients.next(mappedClientsData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)));

        const mappedClientsReviewData = this.mapClientsAndSdrs(response.data.review_clients?.slice() || []);
        this.clientsReview.next(
          mappedClientsReviewData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
        );

        const mappedClientsManualData = this.mapClientsAndSdrs(response.data.manual_clients?.slice() || []);
        this.clientsManual.next(
          mappedClientsManualData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
        );

        const mappedClientsSalesOperatorData = this.mapClientsAndSdrs(
          response.data.sales_operator_clients?.slice() || [],
        );
        this.clientsSales.next(
          mappedClientsSalesOperatorData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
        );

        this.clientsAll.next(
          unionBy(
            mappedClientsData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
            mappedClientsReviewData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
            mappedClientsManualData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
            mappedClientsSalesOperatorData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
            'name',
          ),
        );

        this.clientsSent.next(
          unionBy(
            mappedClientsData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
            mappedClientsReviewData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
            mappedClientsManualData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
            mappedClientsSalesOperatorData.clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null)),
            'name',
          ),
        );

        this.sdrs.next((mappedClientsData.sdrs ?? []).filter((sdr) => !!sdr.email));
        this.sdrsReview.next((mappedClientsReviewData.sdrs ?? []).filter((sdr) => !!sdr.email));
        this.sdrsManual.next((mappedClientsManualData.sdrs ?? []).filter((sdr) => !!sdr.email));
        this.sdrsSales.next((mappedClientsSalesOperatorData.sdrs ?? []).filter((sdr) => !!sdr.email));
        this.sdrsAll.next(
          unionBy(
            (mappedClientsData.sdrs ?? []).filter((sdr) => !!sdr.email),
            (mappedClientsReviewData.sdrs ?? []).filter((sdr) => !!sdr.email),
            (mappedClientsManualData.sdrs ?? []).filter((sdr) => !!sdr.email),
            (mappedClientsSalesOperatorData.sdrs ?? []).filter((sdr) => !!sdr.email),
            'email',
          ),
        );
        this.sdrsSent.next(
          unionBy(
            (mappedClientsData.sdrs ?? []).filter((sdr) => !!sdr.email),
            (mappedClientsReviewData.sdrs ?? []).filter((sdr) => !!sdr.email),
            (mappedClientsManualData.sdrs ?? []).filter((sdr) => !!sdr.email),
            (mappedClientsSalesOperatorData.sdrs ?? []).filter((sdr) => !!sdr.email),
            'email',
          ),
        );
      });
  }

  mapClientsAndSdrs(clients: Array<any>): { clients: Array<any>; sdrs: Array<Sdr> } {
    const sdrs = [];
    clients.forEach((c) => {
      c.sdrs.forEach((sdr) => {
        sdr.clientName = c.name;
        sdr.clientId = c.clientId;
        sdrs.push(
          new Sdr({
            email: sdr.email,
            client: c.name,
            signature: sdr.signature,
            imap: sdr.provider,
            isSendConfigured: sdr.isSendConfigured,
            id: sdr.id,
            clientId: c.clientId,
          }),
        );
      });
    });

    return {
      clients,
      sdrs,
    };
  }

  getAllClients() {
    const endpoint = `${environment.newApiBaseUrl}/configuration/getClientsSdr?includeAll=true`;
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        if (response?.data && response?.data?.clients?.length) {
          response.data.clients = response.data.clients.map((client) => {
            client.sdrs = client.sdrs.map((sdr) => {
              sdr = new Sdr({
                ...sdr,
                client: client.name,
                clientId: client.clientId,
              });
              return sdr;
            });
            return client;
          });
        }

        return response.data;
      }),
    );
  }

  getAllMetabaseResource(resourceType: string) {
    const endpoint = `${environment.newApiBaseUrl}/metabase/get-metabase-resources?resourceType=${resourceType}`;
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        return response.data;
      }),
    );
  }

  getMyMetabaseResource(operatorId: number, resourceType: string) {
    var endpoint = '';
    if (resourceType != null) {
      endpoint = `${environment.newApiBaseUrl}/operators/${operatorId}/get-metabase-resources?resourceType=${resourceType}`;
    } else {
      endpoint = `${environment.newApiBaseUrl}/operators/${operatorId}/get-metabase-resources`;
    }
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        return response.data;
      }),
    );
  }

  getLoggedUserMetabaseResource(resourceType: string) {
    var endpoint = '';
    if (resourceType != null) {
      endpoint = `${environment.newApiBaseUrl}/operators/get-logged-user-metabase-resources?resourceType=${resourceType}`;
    } else {
      endpoint = `${environment.newApiBaseUrl}/operators/get-logged-user-metabase-resources`;
    }
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        return response.data;
      }),
    );
  }

  getDashboardUrl(resourceId: number, resourceType: string) {
    const endpoint = `${environment.newApiBaseUrl}/metabase/resources/${resourceId}/${resourceType}`;
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        return response.data;
      }),
    );
  }

  loadSdrClassifications(folder: string) {
    this.loadingState.setLoadingState(true);
    const endpoint = `${environment.newApiBaseUrl}/configuration/get-sdr-classifications/${folder}`;
    this.sendLog({
      tags: [PRODUCT_TAG, folder, 'loadSdrClassifications', 'POST'],
      body: '',
    }).subscribe();
    this.httpClient.get(endpoint).subscribe((response: any) => {
      this.loadingState.setLoadingState(false);
      const classifications = response.data;
      let options = [];
      if (Array.isArray(classifications)) {
        options = [{ sectionName: '', classifications }];
      } else {
        Object.keys(classifications).forEach((key) => {
          options.push({
            sectionName: key,
            classifications: classifications[key]
              .map((option) => new MailCategory(option))
              .sort((a, b) => Number(a.order) - Number(b.order)),
          });
        });
      }
      this.sdrClassifications.next(options);
    });
  }

  moveMailToFolder(mail: Email, targetFolder: string, currentFolder: string) {
    const endpoint = environment.apiBaseURL + this.operationsSegment + '/move-mail-to-folder';
    const body = {
      uid: mail.uid, // Comment out when ready
      message_id: mail.messageId,
      sdr: mail.sdr,
      destination_folder_id: targetFolder,
      current_folder: currentFolder,
    };
    this.sendLog({
      tags: [PRODUCT_TAG, 'moveMailToFolder', mail.sdr, mail.messageId, currentFolder, 'POST'],
      body,
    }).subscribe();
    return this.post(endpoint, body).pipe(
      catchError((error) => {
        console.log(error);
        this.sendLog({
          tags: [PRODUCT_TAG, 'moveMailToFolder', mail.sdr, mail.messageId, currentFolder, 'ERROR'],
          body: error,
        }).subscribe();
        return of(error);
      }),
      map((response: any) => {
        // console.log(response);
        return response;
      }),
    );
  }

  submitLog(payload) {
    return from(this.authService.getCurrentUserEmail()).pipe(
      switchMap((email) => {
        return this.callSendLog(payload, email);
      }),
    );
  }

  sendLog(payload) {
    // Disabling actual call while we redefine logs to gain performance and cleanup calls
    // return from(this.authService.getCurrentUserEmail())
    //     .pipe(switchMap(email => {
    //       return this.callSendLog(payload,email);
    //     }));

    return of('');
  }

  doSendLog(payload) {
    return from(this.authService.getCurrentUserEmail()).pipe(
      switchMap((email) => {
        return this.callSendLog(payload, email);
      }),
    );
  }

  callSendLog(payload, email: string) {
    const endpoint = environment.apiBaseURL + this.operationsSegment + '/prepare-log';
    const body = {
      timestamp: new Date(), // .toISOString(),
      id: PRODUCT_TAG + '_' + Date().toString(),
      ...payload,
    };
    body.tags.push(email);
    return this.post(endpoint, body).pipe(
      catchError((error) => {
        console.log(error);
        return of(false);
      }),
      map((response: any) => {
        return response ? response.action_id : null;
      }),
    );
  }

  moveMailToTrash(mail: Email) {
    const endpoint = environment.apiBaseURL + this.operationsSegment + '/move-mail-to-trash';
    const body = {
      uid: mail.uid,
      sdr: mail.sdr,
      source_folder_id: mail.folderId,
    };
    this.sendLog({
      tags: [PRODUCT_TAG, 'moveMailToTrash', mail.sdr, mail.uid, 'POST'],
      body,
    }).subscribe();
    return this.post(endpoint, body).pipe(
      catchError((error) => {
        console.log(error);
        this.sendLog({
          tags: [PRODUCT_TAG, 'moveMailToTrash', mail.sdr, mail.uid, 'ERROR'],
          body: error,
        }).subscribe();
        return of(false);
      }),
      map((response: any) => {
        // console.log(response);
        return response ? response.action_id : null;
      }),
    );
  }

  undoAction(actionId: string) {
    const endpoint = environment.apiBaseURL + this.operationsSegment + '/undo-mail-action';
    const body = {
      action_id: actionId,
    };
    this.sendLog({
      tags: [PRODUCT_TAG, 'undoAction', actionId, 'POST'],
      body: '',
    }).subscribe();
    return this.post(endpoint, body).pipe(
      catchError((error) => {
        console.log(error);
        this.sendLog({
          tags: [PRODUCT_TAG, 'undoAction', actionId, 'ERROR'],
          body: error,
        }).subscribe();
        return of(false);
      }),
      map((response: any) => {
        return !!(response && response.success);
      }),
    );
  }

  undoSendAction(actionId: string) {
    // const endpoint = environment.apiBaseURL + this.operationsSegment  + "/undo-mail-action";
    const endpoint = environment.apiBaseURL + this.sendMailSegment + '/send-mail-undo';
    const body = {
      action_id: actionId,
    };
    this.sendLog({
      tags: [PRODUCT_TAG, 'undoAction', actionId, 'POST'],
      body: '',
    }).subscribe();
    return this.post(endpoint, body).pipe(
      catchError((error) => {
        console.log(error);
        this.sendLog({
          tags: [PRODUCT_TAG, 'undoAction', actionId, 'ERROR'],
          body: error,
        }).subscribe();
        return of(false);
      }),
      map((response: any) => {
        return !!(response && response.success);
      }),
    );
  }

  loadUser() {
    if (!this.permissions$) {
      const endpoint = `${environment.newApiBaseUrl}/configuration/get-by-email`;
      this.permissions$ = this.httpClient.get(endpoint).pipe(
        map((response: any) => {
          return new User(
            response.data.email,
            response.data.permissions.map((g) => new Permission(g)),
          );
        }),
        shareReplay(1),
      );
    }
    return this.permissions$;
  }

  // Gets an array of strings (sdrs)
  getAllSdrs() {
    const endpoint = `${environment.newApiBaseUrl}/configuration/user`;
    return this.httpClient.get(endpoint).pipe(map((response: any) => response.data));
  }

  // Gets an array of strings (sdrs)
  getUserSdrs(userEmail: string) {
    const endpoint = `${environment.newApiBaseUrl}/configuration/user`;
    return this.httpClient.get(endpoint).pipe(map((response: any) => response.map((sdr) => sdr.email)));
  }

  getUserData(email: string): Observable<any> {
    const url = `${environment.newApiBaseUrl}/operators/${email}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response.data || {};
      }),
    );
  }

  listSdrByOperator(operatorId: number): Observable<any> {
    const url = `${environment.newApiBaseUrl}/configuration/list-sdr-by-operstor/${operatorId}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response.data || {};
      }),
    );
  }

  // Gets an array of strings (user emails)
  getAllUser() {
    const endpoint = `${environment.newApiBaseUrl}/configuration/operators/`;
    return this.httpClient.get(endpoint).pipe(
      map((response: any) =>
        response.data.map((operator) => ({
          id: operator.id,
          email: operator.email,
        })),
      ),
    );
  }

  // Single endpoint to add/remove sdrs and discrepancies_sdrs
  assignSdr(body: ISaveSdrPayload): Observable<any> {
    const url = `${environment.apiBaseURL}/app/user_operator`;
    return this.httpClient.post(url, body).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  removeSdr(body: ISaveSdrPayload): Observable<any> {
    const url = `${environment.apiBaseURL}/app/user_operator`;
    return this.httpClient.delete(url, { body }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  unassignSdr(operatorId: number, sdrId: number) {
    const url = `${environment.newApiBaseUrl}/configuration/unassign-sdr`;
    const body = {
      userdId: sdrId,
      operatorId: operatorId,
    };
    return this.httpClient.delete(url, { body }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  assignUsersFolders(operatorId: number, body) {
    const url = `${environment.newApiBaseUrl}/operators/${operatorId}/assign-users-folders`;
    return this.httpClient.post(url, body).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  updateUsersFolders(operatorId: number, body) {
    const url = `${environment.newApiBaseUrl}/operators/${operatorId}/update-users-folders`;
    return this.httpClient.post(url, body).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  deleteUsersFolders(operatorId: number, body) {
    const url = `${environment.newApiBaseUrl}/operators/${operatorId}/delete-users-folders`;
    return this.httpClient.post(url, body).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  changePassword(email: string, password: string) {
    const endpoint = environment.apiBaseURL + this.managementSegment + '/manager-change-password';
    const body = {
      user: email,
      password,
    };
    return this.post(endpoint, body).pipe(map((response: any) => response));
  }

  createUser(email: string, password: string) {
    const endpoint = `${environment.apiBaseURL}/app/operators`;
    const body = {
      email,
      password,
    };
    return this.post(endpoint, body).pipe(map((response: any) => response));
  }

  getOperatorCognitoData(email: string): Observable<any> {
    const url = `${environment.apiBaseURL}/app/operators/${email}`;
    return this.httpClient.get(url).pipe(
      map((response: any) => {
        return response.data || {};
      }),
    );
  }

  disableUser(email: string): Observable<any> {
    const url = `${environment.apiBaseURL}/app/operators/${email}`;
    return this.httpClient.patch(url, { enabled: false }).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  addPermission(userId: number, permissionId: number) {
    const endpoint = `${environment.apiBaseURL}/app/operators_roles/`;
    const body = {
      operator_id: userId,
      permission_id: permissionId,
    };
    return this.post(endpoint, body).pipe(map((response: any) => response));
  }

  addMetabase(operatorId: number, userId: number, resourceId: number) {
    const endpoint = `${environment.newApiBaseUrl}/operators/assign-metabase-resources`;
    const body = {
      operatorId: operatorId,
      metabaseResourceId: resourceId,
      userCreated: userId,
      userUpdated: 0,
    };
    return this.post(endpoint, body).pipe(map((response: any) => response));
  }

  removeMetabase(id: number) {
    const endpoint = `${environment.newApiBaseUrl}/operators/delete-metabase-resources?id=${id}`;
    return this.httpClient.delete(endpoint).pipe(map((response: any) => response));
  }

  removePermission(userId: number, permissionId: number) {
    const endpoint = `${environment.apiBaseURL}/app/operators_roles?operator_id=${userId}&permission_id=${permissionId}`;
    const body = {
      operator_id: userId,
      permission_id: permissionId,
    };
    return this.httpClient.delete(endpoint).pipe(map((response: any) => response));
  }

  // Gets an array of strings
  getUserPermissions(userEmail: string): Observable<string[]> {
    const endpoint = `${environment.newApiBaseUrl}/configuration/get-by-email?email=${userEmail}`;
    return this.httpClient.get(endpoint).pipe(map((response: any) => response.data.permissions));
  }

  // Gets an array of strings
  getAllPermissions(): Observable<{ name: string; id: number }[]> {
    const endpoint = `${environment.newApiBaseUrl}/configuration/permission`;
    return this.httpClient.get(endpoint).pipe(map((response: any) => response.data || []));
  }

  postSlackMessage(message: string) {
    const endpoint = environment.slackBotURL;
    const body = {
      channel: 'sd-om-alerts',
      text: '\n ```' + window.location.hostname + ' \n' + message + '```',
    };
    return this.post(endpoint, body).pipe(map((response: any) => response));
  }

  // Gets an array of strings (sdrs)
  searchInquiryLead(
    sdr: string,
    searchDate: string,
    email: string,
    firstName: string,
    lastName: string,
  ): Observable<IInquiryLead[]> {
    const endpoint = environment.newApiBaseUrl + '/configuration/inquire/search';
    const body = {
      sdr,
      date: searchDate,
      email,
      firstName: firstName,
      lastName: lastName,
    };
    return this.post(endpoint, body).pipe(
      map((response: any) => {
        return response?.data?.map((lead) => new InquiryLead(lead)) || [];
      }),
    );
    catchError((error) => {
      return of([]);
    });
  }

  addAddressSuggestion(client: string, email: string) {
    const endpoint = environment.newApiBaseUrl + '/user-contact-emails/create';
    const body = {
      client,
      contactEmail: email,
    };
    return this.post(endpoint, body).pipe(map((response: any) => response));
  }

  removeAddressSuggestion(clientAddressId: string) {
    const endpoint = environment.newApiBaseUrl + `/user-contact-emails/delete?id=${clientAddressId}`;
    return this.httpClient.delete(endpoint).pipe(map((response: any) => response));
  }

  // Gets an array of strings
  updateAddressSuggestion(clientAddressId: string, client: string, email: string) {
    const endpoint = environment.newApiBaseUrl + '/user-contact-emails/update';
    const body = {
      id: clientAddressId,
      client,
      contactEmail: email,
    };
    return this.httpClient.patch(endpoint, body).pipe(map((response: any) => response));
  }

  getAddressSuggestions() {
    const endpoint = environment.newApiBaseUrl + '/user-contact-emails/list-user-contact-emails';
    return this.httpClient.get(endpoint).pipe(map((response: any) => response));
  }

  loadMultipleMailFeed(
    sdrs: string[],
    folder: string,
    pageNumber: number,
    pageKeyMap: any[],
    lastPageSubject: BehaviorSubject<boolean>,
  ): Observable<Email[]> {
    this.sendLog({
      tags: [PRODUCT_TAG, sdrs.join(','), folder, 'GetTerminalFeed2', 'POST'],
      body: '',
    }).subscribe();
    this.loadingState.setLoadingState(true);
    this.loadingState.setFeedLoadingState(true);
    const endpoint = `${environment.apiBaseURL}/app/mails/v2/emails_sent`;
    const body = {
      sdr: sdrs.toString(),
      page_size: 50,
      status: '',
      next_key: '',
    };

    if (pageNumber !== 0) {
      body.next_key = pageKeyMap[pageNumber - 1];
    }

    return this.httpClient.post(endpoint, body).pipe(
      map((response: any) => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
        this.sendLog({
          tags: [PRODUCT_TAG, sdrs.join(','), folder, 'GetTerminalFeed2', 'SUCCESS'],
          body: '',
        }).subscribe();
        if (pageKeyMap.length < pageNumber + 1) {
          pageKeyMap.push(response.data.key);
        } else {
          pageKeyMap[pageNumber] = response.data.key;
        }

        if (!response.data.key) {
          lastPageSubject.next(true);
        } else {
          lastPageSubject.next(false);
        }
        const feed: any[] = response ? response.data.feed : [];
        return feed.map((mailData: any) => {
          mailData.folder = folder;
          // Name remapping
          mailData.from = mailData.from_email || '';
          mailData.to = mailData.to_email || '';
          mailData.date = mailData.sent_date || '';
          mailData.from_ = mailData.from_name_and_email || '';
          mailData.to_ = mailData.to_name_and_email || '';
          mailData.cc_ = mailData.cc_name_and_email || '';
          mailData.sdrName = mailData.sdr_name || '';
          mailData.sendStatus = mailData.sent_status;
          mailData.errorMessage = mailData.error_message;

          if (mailData.attachments) {
            mailData.attachments = mailData.attachments.map((attachment: IAttachment) => {
              return new Attachment({
                filename: attachment.name,
                key: attachment.path,
                isSafe: attachment.isSafe,
                fileId: 'deprecated',
                size: attachment.size,
                isLocal: false,
                contentType: attachment.contentType,
              });
            });
          }

          return new Email(mailData);
        });
      }),
      catchError((error) => {
        this.loadingState.setLoadingState(false);
        this.loadingState.setFeedLoadingState(false);
        this.sendLog({
          tags: [PRODUCT_TAG, sdrs.join(','), folder, 'GetTerminalFeed2', 'ERROR'],
          body: error,
        }).subscribe();
        console.log(error);
        return of([]);
      }),
    );
  }

  getAttachmentData(url: string): Observable<Blob> {
    return this.httpClient
      .get(url, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return new Blob([res.body], { type: res.headers.get('Content-Type') });
        }),
      );
  }

  requestUploadSignature(filename) {
    const endpoint = environment.apiBaseURL + this.documentSegment;
    const body = {
      object: filename,
    };
    return this.httpClient.post<any>(endpoint, body);
  }

  async requestUploadSignature2(file: File) {
    const endpoint = environment.apiBaseURL + '/app/file_url';
    const arrayBuffer = await new Response(file).arrayBuffer();

    const md5Util = new Md5();
    const byteArray = new Uint8Array(arrayBuffer);
    const md5Hash = md5Util.appendByteArray(byteArray).end();

    const body = {
      key: md5Hash,
      size: file.size,
    };
    return this.httpClient.post<any>(endpoint, body);
  }

  requestDownloadSignature(filename) {
    const endpoint = environment.apiBaseURL + this.documentSegment + '/download';
    // filename="yes.jpg";
    const body = {
      object: filename,
    };
    return this.httpClient.post<string>(endpoint, body);
  }

  uploadAttachment(presignedPostData: any, file: File) {
    const formData: FormData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    formData.append('file', file);

    const req = new HttpRequest('POST', `${presignedPostData.url}`, formData, {
      reportProgress: true,
      responseType: 'json',
    });

    return this.httpClient.request(req);
  }

  deleteAttachment(fileId) {
    const endpoint = environment.apiBaseURL + this.sendMailSegment + '/remove_s3';
    const body = {
      fileId,
    };
    return this.httpClient.post(endpoint, body);
    // return of('');
  }

  getAttachmentParameters() {
    const endpoint = environment.apiBaseURL + this.sendMailSegment + '/parameters';
    const body = {};
    return this.post(endpoint, body).pipe(map((response: any) => response));
  }

  getBlockedSdrs() {
    const endpoint = `${environment.newApiBaseUrl}/configuration/blocked-sdrs`;
    return this.httpClient.get(endpoint).pipe(map((response: any) => response.data));
  }

  sendEmail(composeEmail: ComposeEmail) {
    const endpoint = environment.newApiBaseUrl + '/emails/sendEmail';

    let strType = '';
    switch (composeEmail.category) {
      case ComposeEmailCategory.New:
        strType = 'NEW';
        break;
      case ComposeEmailCategory.Reply:
        strType = 'RE';
        break;
      case ComposeEmailCategory.Forward:
        strType = 'FWD';
        break;
      case ComposeEmailCategory.Resend:
        strType = 'NEW';
        break;
    }

    let bodyHtml = '';

    if (composeEmail.category != ComposeEmailCategory.New && composeEmail.includeChainInEditor) {
      bodyHtml = composeEmail.currentEdition;
    } else {
      // Insert default font family and size ?
      if (composeEmail.sender == 'gmail') {
        bodyHtml =
          '<div style="font-size:' +
          AppConstants.GMAIL_FONT_SIZE +
          AppConstants.GMAIL_FONT_UNIT +
          ';font-family:' +
          AppConstants.GMAIL_FONT_FAMILY +
          ';"> ' +
          composeEmail.currentEdition +
          ' </div> ' +
          composeEmail.bodyHtml;
        '<div style="font-size:13px;font-family:Arial;"> ' +
          composeEmail.currentEdition +
          ' </div> ' +
          composeEmail.bodyHtml;
      } else {
        bodyHtml =
          '<div style="font-size:' +
          AppConstants.OUTLOOK_FONT_SIZE +
          AppConstants.OUTLOOK_FONT_UNIT +
          ';font-family:' +
          AppConstants.OUTLOOK_FONT_FAMILY +
          ';"> ' +
          composeEmail.currentEdition +
          ' </div> ' +
          composeEmail.bodyHtml;
      }
    }
    const toEmail = composeEmail.to.join();
    const html = bodyHtml || composeEmail.bodyPlain;
    const body = {
      sdr: composeEmail.fromSdrId, // composeEmail.from Add composeEmail.IdFrom with sdr id
      sending_type: strType,
      message: {
        from_email: composeEmail.from,
        to_email: toEmail,
        cc_email: composeEmail.cc.join(),
        bcc_email: composeEmail.bcc.join(),
        subject: composeEmail.subject,
        body_html: html,
        attachments: composeEmail.attachments
          ? composeEmail.attachments.map((attachment: any) => {
              return {
                name: attachment.filename,
                key: attachment.key,
                is_safe: true,
                size: attachment.size,
                content_type: attachment.contentType,
              };
            })
          : [],
        references: Array.isArray(composeEmail.references)
          ? composeEmail.references.length > 0
            ? composeEmail.references.join(', ')
            : ''
          : composeEmail.references || '',
        in_reply_to: composeEmail.in_reply_to ? composeEmail.in_reply_to : '',
        message_id: composeEmail.messageId,
        template: composeEmail.template,
        sales_representative: toEmail.includes(composeEmail.salesRepresentative)
          ? composeEmail.salesRepresentative
          : null,
      },
      original_storage_id: composeEmail.sourceStorageId,
    };

    this.doSendLog({
      tags: [PRODUCT_TAG, 'send', composeEmail.from, composeEmail.to, composeEmail.subject, 'POST'],
      body,
    }).subscribe();
    return this.post(endpoint, body).pipe(
      catchError((error) => {
        console.log(error);
        this.doSendLog({
          tags: [PRODUCT_TAG, 'send', composeEmail.from, composeEmail.to, composeEmail.subject, 'ERROR'],
          body: error,
        }).subscribe();
        return of(false);
      }),
      map((response: any) => {
        return response;
      }),
    );
  }

  uploadSdrCongiruation(provider: string, domain: string, configuration: string) {
    const endpoint = environment.apiBaseURL + this.managementSegment + '/manager-upload-sdr-configuration';
    const body = {
      provider,
      domain,
      configuration,
    };
    return of({
      success: true,
      error: '',
    });
  }

  getDownloadUrl(messageHandlerId: string, filename: string) {
    const endpoint = `${environment.newApiBaseUrl}/emails/get-by-message/${messageHandlerId}/attachments/${filename}`;

    return endpoint;
  }

  getDownloadUrlSent(key) {
    const endpoint = environment.apiBaseURL + this.operationsSegment + '/get_file_url?key=' + key;

    return this.httpClient.get<any>(endpoint);
  }

  getAttachmentFromUrl(url: any): Observable<Blob> {
    return this.httpClient
      .get(url, {
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map((res) => {
          return new Blob([res.body], { type: res.headers.get('Content-Type') });
        }),
      );
  }

  getReplyTemplates(): Observable<Template[]> {
    const endpoint = environment.newApiBaseUrl + '/template/filter_template/1';
    return this.httpClient.get<any>(endpoint).pipe(
      map((response: any) => {
        response.data = response.data.map((template) => {
          return new Template(template);
        });

        return response.data;
      }),
    );
  }

  getIntroductionTemplates() {
    const endpoint = environment.newApiBaseUrl + '/template/filter_template/2';
    return this.httpClient.get<any>(endpoint).pipe(
      map((response: any) => {
        response.data = response.data.map((data) => {
          let hasAttachments = false;
          data.attachment = JSON.parse(data.attachment);
          if (data.attachment) {
            hasAttachments = data.attachment.length > 0;
          }
          return {
            id: data.templateId,
            sortDate: new Date(data.dateCreated),
            date: this.formatDate(new Date(data.dateCreated)),
            title: data.title,
            hasAttachments,
            client: data.client ? data.client : '',
            sdr: data.sdr ? data.sdr : '',
            clientContact: data.clientContact ? data.clientContact : '',
            tags: data.tags,
          };
        });

        return response.data;
      }),
    );
  }

  removeTemplate(templateId: string): Observable<ITemplateDeleteResponse> {
    const url = `${environment.newApiBaseUrl}/template/delete?id=${templateId}`;

    return this.httpClient.delete<ITemplateDeleteResponse>(url).pipe(map((response) => response));
  }

  createOrUpdateTemplate(
    mode,
    templateId,
    templateType,
    title,
    clientId,
    templateText,
    notes,
    tags,
    attachments,
    cc,
    bcc,
    sdrs,
    salesRep,
    userCreated,
    userUpdated,
    isInternalUse,
  ) {
    let endpoint = environment.newApiBaseUrl + '/template/create';
    const body: any = {
      id: templateId,
      template_type_id: templateType,
      title,
      client_id: clientId,
      template_text: templateText,
      notes,
      tags,
      attachment: attachments.map((attachment: any) => {
        return {
          name: attachment.filename,
          key: attachment.key,
          is_safe: true,
          size: attachment.size,
          content_type: attachment.contentType,
        };
      }),
      cc,
      bcc,
      sdrs,
      client_contact_id: salesRep,
      user_created: userCreated == null ? '' : userCreated,
      user_updated: userUpdated == null ? '' : userUpdated,
      internal_use_only: isInternalUse,
    };

    if (mode === 'new') {
      return this.post(endpoint, body).pipe(map((response: any) => response));
    } else {
      endpoint = environment.newApiBaseUrl + '/template/update';
      return this.httpClient.patch(endpoint, body).pipe(map((response: any) => response));
    }
  }

  getClientInfo(status: string = '') {
    let endpoint = `${environment.newApiBaseUrl}/configuration/get_clients_info`;
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        return {
          data: response.data.map((client) => {
            return {
              id: client.clientId,
              name: client.clientName,
              sdrs: client.sdrs,
              salesReps: client.contactEmails,
            };
          }),
        };
      }),
    );
  }

  loadTemplate(templateId) {
    const endpoint = environment.newApiBaseUrl + '/template/get_template_by_id/' + templateId;
    return this.httpClient.get(endpoint).pipe(
      map((response: any) => {
        response.data.Attachment = JSON.parse(response.data.attachment);
        return response.data;
      }),
    );
  }

  formatDate(date) {
    return [this.padTo2Digits(date.getDate()), this.padTo2Digits(date.getMonth() + 1), date.getFullYear()].join('/');
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  getLastClientContact(customerId: number): Observable<any> {
    return this.httpClient
      .get<any>(`${environment.apiBaseURL}/app/last_client_contact/get_last_client_contact?customer_id=${customerId}`)
      .pipe(map((response: any) => response));
  }

  downloadSimulationReport(dateFrom: string, dateTo: string) {
    let endpoint = `${environment.apiBaseURL}/app/reports/classified_report?start_date=${dateFrom}&end_date=${dateTo}&simulation=true`;
    return this.httpClient.get(endpoint).pipe(map((response: any) => response.data));
  }

  downloadClassificationReport(dateFrom: string, dateTo: string) {
    let endpoint = `${environment.apiBaseURL}/app/reports/classified_report?start_date=${dateFrom}&end_date=${dateTo}&simulation=false`;
    return this.httpClient.get(endpoint).pipe(map((response: any) => response.data));
  }

  private post(endpoint: string, body: any) {
    return this.httpClient.post(endpoint, body);
  }
}
