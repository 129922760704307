import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { EMPTY, forkJoin, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ConfirmDialogComponent } from '../../../components/confirm-dialog/confirm-dialog.component';
import { ClassificationStatusEnum } from '../../../model/common/classification-status.enum';
import { ConvertedDialogData } from '../../../model/common/converted-dialog-data.model';
import { FunctionName } from '../../../model/common/function-name.enum';
import { ResponseStatus } from '../../../model/common/generic-response';
import { ServiceInfo } from '../../../model/common/service-info.model';
import { ServiceInstance } from '../../../model/common/service-instance.enum';
import { CountryEnum } from '../../../model/converted-dialog/country.enum';
import { IndustryEnum } from '../../../model/converted-dialog/industry.enum';
import { Forwarder } from '../../../model/interaction/forwarder.model';
import { CatalogCollection } from '../../../model/mail-classifier/catalog-collection.model';
import { ClassificationStatusData } from '../../../model/mail-classifier/classification-status-data.model';
import { ClassifierMessage } from '../../../model/mail-classifier/classifier-message.model';
import { ClientRepresentative, Representative } from '../../../model/mail-classifier/client-representative.model';
import { LastForwarder } from '../../../model/mail-classifier/last-forwarder.model';
import { LeadGenSources } from '../../../model/mail-classifier/lead-gen-sources.model';
import { ProcessConversionDto } from '../../../model/mail-classifier/process-conversion.dto';
import { UpdateConversionDto } from '../../../model/mail-classifier/update-conversion.dto';
import { ProcessMessageConversionDto } from '../../../model/sdr-conversations/process-message-conversion.dto';
import { ClassifyMessageService } from '../../../services/classify-message.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { InteractionService } from '../../../services/interaction.service';
import { MailReviewService } from '../../../services/mail-review.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-converted-dialog',
  templateUrl: './converted-dialog.component.html',
  styleUrls: ['./converted-dialog.component.scss'],
})
export class ConvertedDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  convertedForm: FormGroup;
  clientRepresentatives: ClientRepresentative;
  catalogs: CatalogCollection = null;
  leadGenSources: LeadGenSources[] = [];
  isNewInteraction = false;
  score = null;
  isSaving = false;
  forwarders: Forwarder[] = [];
  lastForwarder: LastForwarder = null;
  hideAdditionalFields = false;
  currentMessage: ClassifierMessage = null;
  private destroy$ = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public convertedDialogData: ConvertedDialogData,
    public dialogRef: MatDialogRef<ConvertedDialogComponent>,
    private fb: FormBuilder,
    private configurationService: ConfigurationService,
    private classifyMessageService: ClassifyMessageService,
    private interactionService: InteractionService,
    private mailReviewService: MailReviewService,
    private matDialog: MatDialog,
    private snackBarService: SnackBarService,
  ) {
    const fromMail = this.convertedDialogData?.fromMail?.toLowerCase();
    if (
      !this.convertedDialogData.isMessageClassification &&
      fromMail !== this.convertedDialogData?.contact?.emailAddress?.toLowerCase()
    ) {
      this.openForwarderDialog();
    }

    this.isNewInteraction =
      this.convertedDialogData?.interaction?.prospectStatus !== ClassificationStatusEnum.ConvertedLead;

    const businessValue = this.convertedDialogData?.interaction?.businessValue;
    this.score = businessValue ? this.formatNumber(businessValue) : null;

    this.createForm();
  }

  ngOnInit(): void {
    this.configurationService.clientRepresentatives$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (clientRepresentatives) => {
        if (clientRepresentatives) {
          const geographicZone = this.convertedDialogData?.isConvertedChannel ? 'Referral Channel' : 'ALL';

          if (!this.convertedDialogData?.isMessageClassification) {
            clientRepresentatives.representatives = this.filterRepresentativesByZone(
              clientRepresentatives.representatives,
              geographicZone,
            );
          }

          this.clientRepresentatives = clientRepresentatives;

          if (this.clientRepresentatives?.useFullInteraction && !this.convertedDialogData?.isConvertedChannel) {
            this.setFullFormValidators();
          }

          if (this.clientRepresentatives?.representatives?.length === 1) {
            this.convertedForm
              .get('salesRepId')
              .setValue(this.clientRepresentatives?.representatives[0]?.clientContactId);
          }
        } else {
          this.convertedForm.get('salesRepId').disable();
          this.snackBarService.showError('No client representatives found');
        }
      },
    });
    this.loadCatalogs();
  }

  ngAfterViewInit(): void {
    this.mailReviewService.currentClassifierMessage$.pipe(takeUntil(this.destroy$)).subscribe((currentMessage) => {
      this.currentMessage = currentMessage;
    });

    this.convertedForm
      .get('bdMatrixId')
      ?.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => this.calculateScore());

    this.convertedForm
      .get('bdDelayId')
      ?.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => this.calculateScore());

    this.convertedForm
      .get('bdCountryId')
      ?.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => this.calculateScore());

    this.convertedForm
      .get('bdIndustryId')
      ?.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => this.calculateScore());

    this.convertedForm
      .get('bdSourceId')
      ?.valueChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => this.calculateScore());

    this.mailReviewService.lastForwarder$.pipe(takeUntil(this.destroy$)).subscribe((lastForwarder) => {
      this.lastForwarder = lastForwarder;

      if (lastForwarder?.forwarderId && this.isNewInteraction) {
        this.getForwarders();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private filterRepresentativesByZone(representatives: Representative[], zone: string): Representative[] {
    return representatives.filter((rep) => rep.geographicZone === zone);
  }

  openForwarderDialog() {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Emails',
      message: 'Different email, Include in forwarder?',
    };
    const confirmDialogRef = this.matDialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.convertedForm.get('comments').setValue(this.forwarders[0]?.originalEmail);
      }
    });
  }

  getForwarders() {
    const contactId = this.lastForwarder.forwarderId;
    const sdrId = this.currentMessage?.sdrId;

    if (!sdrId || !contactId) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to get the forwarders ',
      );
      return;
    }

    this.interactionService.getForwarders(contactId, sdrId).subscribe({
      next: (forwarders) => {
        this.forwarders = forwarders?.length ? forwarders : [];
        if (this.convertedDialogData.contact.emailAddress === this.forwarders[0].email) {
          this.convertedForm.get('comments').setValue(this.forwarders[0]?.originalEmail);
        }
      },
      error: () => {
        this.snackBarService.showError('An error occurred while getting forwarders');
        this.interactionService.setForwarders(null);
      },
    });
  }

  setForwarder(forwarderEmail: string) {
    this.convertedForm.get('comments').setValue(forwarderEmail);
  }

  get bdsourceTooltip() {
    return this.leadGenSources.find((source) => source?.id === this.convertedForm?.get('bdSourceId')?.value)?.name;
  }

  calculatedDelay() {
    const { utcDatetimeInteraction } = this.convertedDialogData.interaction;
    const { interactionUtcDatetime: sendDate } = this.convertedDialogData;
    const today = new Date(sendDate);
    const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime();
    const interactionDate = new Date(utcDatetimeInteraction);
    const interactionDateOnly = new Date(
      interactionDate.getFullYear(),
      interactionDate.getMonth(),
      interactionDate.getDate(),
    ).getTime();
    const differenceInDays = Math.floor((todayDateOnly - interactionDateOnly) / (1000 * 60 * 60 * 24));

    return this.getDelayIdByDays(differenceInDays);
  }

  calculateScore() {
    if (this.catalogs && this.leadGenSources?.length) {
      const { bdMatrixId, bdDelayId, bdCountryId, bdIndustryId, bdSourceId } = this.convertedForm.getRawValue();
      const bdMatrixValue = this.catalogs?.matrix?.find((matrix) => matrix.id === bdMatrixId)?.value;
      const bdDelayValue = this.catalogs?.delay?.find((delay) => delay.id === bdDelayId)?.value;
      const bdCountryValue = this.catalogs?.country?.find((country) => country.id === bdCountryId)?.value;
      const bdIndustryValue = this.catalogs?.industry?.find((industry) => industry.id === bdIndustryId)?.value;
      const bdSourceIdFactor = this.leadGenSources?.find((source) => source.id === bdSourceId)?.factor;

      if (bdMatrixId && bdDelayId && bdCountryId && bdIndustryId && bdSourceId) {
        const calculateScore = bdMatrixValue * bdDelayValue * bdCountryValue * bdIndustryValue * bdSourceIdFactor;
        this.score = calculateScore ? this.formatNumber(calculateScore) : 0;
      }
    }
  }

  formatNumber(value: number) {
    return Number.isInteger(value) ? value.toString() : value.toFixed(3);
  }

  loadCatalogs() {
    forkJoin({
      catalogs: this.configurationService.getCatalogs().pipe(
        catchError(() => {
          this.snackBarService.showError('An error occurred while trying to get the catalogs');
          return EMPTY;
        }),
      ),
      leadGenSources: this.configurationService.getLeadGenSources().pipe(
        catchError(() => {
          this.snackBarService.showError('An error occurred while trying to get the sources');
          return EMPTY;
        }),
      ),
    })
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.calculateScore()),
      )
      .subscribe(({ catalogs, leadGenSources }) => {
        if (catalogs) {
          this.catalogs = catalogs;
          this.setDefaultBdCountryId();
          this.setDefaultBdIndustryId();
          this.setdefaultBdDelayId();
        }

        if (leadGenSources) {
          this.leadGenSources = leadGenSources.filter(
            (source) => source.customerId === this.clientRepresentatives?.clientId,
          );
          this.setDefaultBdSourceId();
        }

        this.calculateScore();
      });
  }

  setDefaultBdSourceId() {
    if (!this.convertedForm.get('bdSourceId').value) {
      const defaultBdSourceId = this.leadGenSources.find((source) => source.isDefault)?.id;

      this.convertedForm.get('bdSourceId').setValue(defaultBdSourceId || null);
    }
  }

  setDefaultBdCountryId() {
    if (!this.convertedForm.get('bdCountryId').value) {
      const countryName =
        this.convertedDialogData.contact?.country === 'United States' ? CountryEnum.UnitedStates : CountryEnum.NonUS;
      const defaultBdCountryId = this.catalogs?.country.find((country) => country.name === countryName)?.id;

      this.convertedForm.get('bdCountryId').setValue(defaultBdCountryId || null);
    }
  }

  setDefaultBdIndustryId() {
    if (!this.convertedForm.get('bdIndustryId').value) {
      const industryName =
        this.convertedDialogData.contact?.industry === IndustryEnum.StaffingRecruiting
          ? IndustryEnum.StaffingRecruiting
          : IndustryEnum.Others;
      const defaultBdIndustryId = this.catalogs?.industry.find((industry) => industry.name === industryName)?.id;

      this.convertedForm.get('bdIndustryId').setValue(defaultBdIndustryId || null);
    }
  }

  setdefaultBdDelayId() {
    if (!this.convertedForm.get('bdDelayId').value) {
      const defaultBdDelayId = this.calculatedDelay();
      this.convertedForm.get('bdDelayId').setValue(defaultBdDelayId || null);
    }
  }

  createForm() {
    const {
      interaction: {
        firstName: interactionFirstName,
        middleName: interactionMiddleName,
        lastName: interactionLastName,
        jobTitle: interactionJobTitle,
        comments,
        bdSourceId,
        bdMatrixId,
        bdCountryId,
        bdDelayId,
        bdIndustryId,
        salesRepId,
        jobPostingId,
      },
      contact: {
        firstName: contactFirstName,
        middleName: contactMiddleName,
        lastName: contactLastName,
        jobTitle: contactJobTitle,
      },
    } = this.convertedDialogData;

    this.convertedForm = this.fb.group({
      firstName: [interactionFirstName || contactFirstName || '', Validators.required],
      middleName: [interactionMiddleName || contactMiddleName || ''],
      lastName: [interactionLastName || contactLastName || '', Validators.required],
      jobTitle: [interactionJobTitle || contactJobTitle || '', Validators.required],
      jobPosting: [jobPostingId || ''],
      comments: [comments?.toLowerCase() || ''],
      bdSourceId: [bdSourceId || null],
      bdMatrixId: [bdMatrixId || null],
      bdCountryId: [bdCountryId || null],
      bdDelayId: [bdDelayId || null],
      bdIndustryId: [bdIndustryId || null],
      salesRepId: [salesRepId || null, Validators.required],
    });
  }

  getDelayIdByDays(days) {
    let delayId = null;
    if (days >= 0 && days <= 4) {
      delayId = this.catalogs?.delay.find((d) => d.name === 'Delay 0-4').id;
    } else if (days >= 5 && days <= 19) {
      delayId = this.catalogs?.delay.find((d) => d.name === 'Delay 5-19').id;
    } else if (days >= 20) {
      delayId = this.catalogs?.delay.find((d) => d.name === 'Delay >=20').id;
    }

    return delayId;
  }

  handleConversion() {
    if (this.convertedDialogData.isMessageClassification) {
      this.handleProcessMessageConversion();
      return;
    }
    this.isNewInteraction ? this.handleProcessConversion() : this.handleUpdateConversion();
  }

  handleProcessConversion() {
    const {
      interaction: { messageId, interactionId, sdrId },
      contact: { contactId },
      ruleId,
      messageHandlerIds,
      replyMessageId,
      interactionUtcDatetime,
      isConvertedChannel,
    } = this.convertedDialogData;
    const { jobTitle, ...rest } = this.convertedForm.value;
    const processConversionDto: ProcessConversionDto = {
      registerConversionPayload: {
        ...rest,
        ...(environment.isProd && { jobTitle }),
        interactionId,
        contactId,
        messageId,
        sdrId,
        replyMessageId,
        interactionUtcDatetime,
        conversionType: isConvertedChannel ? 2 : 1,
      },
      ruleId,
      messageHandlerIds,
    };

    this.processConversion(processConversionDto);
  }

  processConversion(processConversionDto: ProcessConversionDto) {
    this.isSaving = true;

    this.interactionService
      .processConversion(processConversionDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isSaving = false)),
      )
      .subscribe({
        next: (response) => {
          if (response?.status === ResponseStatus.Error) {
            const message = response?.message || 'An error occurred while trying to classify';
            this.snackBarService.showError(message);
            return;
          }
          this.snackBarService.showSuccess(response?.message || 'The classification was carried out successfully');
          this.interactionService.setInteractionsUpdated(true);
          this.dialogRef.close();
        },
        error: (error: HttpErrorResponse) => {
          this.snackBarService.showError(
            JSON.parse(error?.error)?.message || `An error occurred while trying to classify`,
          );
        },
      });
  }

  handleUpdateConversion() {
    const {
      interaction: { messageId, interactionId, sdrId },
      contact: { contactId },
      interactionUtcDatetime,
    } = this.convertedDialogData;
    const { jobTitle, ...rest } = this.convertedForm.value;
    const updateConversionDto: UpdateConversionDto = {
      ...rest,
      ...(environment.isProd && { jobTitle }),
      interactionId,
      contactId,
      messageId,
      sdrId,
      interactionUtcDatetime,
    };

    this.updateConversion(updateConversionDto);
  }

  updateConversion(updateConversionDto: UpdateConversionDto) {
    this.isSaving = true;
    updateConversionDto.comments = updateConversionDto.comments?.toLowerCase();
    this.interactionService
      .updateConversion(updateConversionDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => (this.isSaving = false)),
      )
      .subscribe({
        next: (response) => {
          if (response?.status === ResponseStatus.Error) {
            const message = response?.message || 'An error occurred while trying to classify';
            this.snackBarService.showError(message);
            return;
          }
          this.snackBarService.showSuccess(response?.message || 'The classification was carried out successfully');
          this.interactionService.setInteractionsUpdated(true);
          this.dialogRef.close();
        },
        error: () => {
          this.snackBarService.showError(`An error occurred while trying to classify`);
        },
      });
  }

  handleProcessMessageConversion() {
    const {
      interaction: { messageId, interactionId, sdrId },
      contact: { contactId },
      ruleId,
      messageHandlerIds,
      replyMessageId,
      interactionUtcDatetime,
    } = this.convertedDialogData;
    const { jobTitle, bdMatrixId, bdDelayId, bdCountryId, bdIndustryId, bdSourceId, ...rest } =
      this.convertedForm.value;
    const processMessaggeConversionDto: ProcessMessageConversionDto = {
      registerConversionPayload: {
        ...rest,
        ...(environment.isProd && { jobTitle }),
        interactionId,
        contactId,
        messageId,
        sdrId,
        replyMessageId,
        interactionUtcDatetime,
      },
      ruleId,
      messageHandlerIds,
    };

    const classification: ClassificationStatusData = {
      classificationId: ClassificationStatusEnum.Converted,
      classificationName: ClassificationStatusEnum.Converted,
    };

    this.processMessageConversion(processMessaggeConversionDto, classification);
  }

  processMessageConversion(
    processMessaggeConversionDto: ProcessMessageConversionDto,
    classification: ClassificationStatusData,
  ) {
    if (!Object.values(processMessaggeConversionDto).every((value) => value)) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to run the classification.',
      );
      return;
    }
    this.dialogRef.close();

    const serviceInfo: ServiceInfo = {
      serviceInstance: ServiceInstance.InteractionService,
      functionName: FunctionName.ProcessMessageConversion,
    };

    this.classifyMessageService.classification = classification;
    this.classifyMessageService.classifyMessage(serviceInfo, processMessaggeConversionDto);
  }

  private setFullFormValidators(): void {
    const controlNames = ['bdSourceId', 'bdMatrixId', 'bdCountryId', 'bdDelayId', 'bdIndustryId'];

    for (const controlName of controlNames) {
      this._setValidators(controlName, [Validators.required]);
    }
    this._updateAllFormControlsValidity();
  }

  private _setValidators(controlName: string, validators: ValidatorFn[]): void {
    const control = this.convertedForm.get(controlName);
    if (control) {
      control.setValidators(validators);
    }
  }

  private _updateAllFormControlsValidity(): void {
    Object.keys(this.convertedForm.controls).forEach((controlName) => {
      const control = this.convertedForm.get(controlName);
      if (control) {
        control.updateValueAndValidity();
      }
    });
  }
}
