import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from '../../../../components/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../../../../components/alert-dialog/alert-dialog.component';
import { LoadingStateService } from '../../../../services/base/loading-state.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { SDAuthService } from 'src/app/services/sd-auth.service';

const MODAL_DURATION = 3000;

interface ISdr {
  email: string;
  id: number;
  customerName: string;
  isDefault: boolean;
  isManualDiscrepancy: boolean;
  isReviewDiscrepancy: boolean;
  isSalesOperation: boolean;
}

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('permissionSort') permissionSort: MatSort;
  @ViewChild('permissionsPaginator') permissionPaginator: MatPaginator;

  constructor(
    private service: SdApiService,
    private authService: SDAuthService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private loadingStateService: LoadingStateService,
    private snackBar: MatSnackBar,
  ) {}

  loading = false;
  permissionColumns: string[] = ['permission', 'actions'];

  permissionSearchKey: string;
  userEmail: string | null;
  userId: number;
  operatorId: number;
  clientSdrs: any[] = [];
  allSdrs: ISdr[] = [];
  userFolderSdrs: ISdr[] = [];
  discrepancySdrs: ISdr[] = [];
  reviewDiscrepancySdrs: ISdr[] = [];
  salesOperationsSdrs: ISdr[] = [];
  inboxReferralSdrs: ISdr[] = [];
  sdrsListData: MatTableDataSource<any> = new MatTableDataSource();
  permissionListData: MatTableDataSource<any> = new MatTableDataSource();
  discrepancySdrsDataSource: MatTableDataSource<any> = new MatTableDataSource();
  reviewDiscrepancySdrsDataSource: MatTableDataSource<any> = new MatTableDataSource();
  salesOperationsSdrsDataSource: MatTableDataSource<any> = new MatTableDataSource();
  inboxReferralSdrsDataSource: MatTableDataSource<any> = new MatTableDataSource();
  availablePermissions: { name: string; id: number }[] = [];
  selectedPermission: string;
  newPassword = '';
  passwordPattern =
    '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#=+$@$!%*?&§\\[\\]\\{\\}"\'\\(\\)\\^_\\-\\~\\|\\`\\<\\>\\,\\.\\;¨])[A-Za-zd#=+$@$!%*?&§\\[\\]\\{\\}"\'\\(\\)\\^_\\-\\~\\|\\`\\<\\>\\,\\.\\;¨].{8,15}$';

  public permissionFilterControl = new UntypedFormControl();
  filteredPermissionOptions: Observable<any[]>;
  isUserEnabled = false;
  showDisableBtn = false;

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.userEmail = params.get('userEmail');
      if (this.userEmail) {
        combineLatest([
          this.authService.getCurrentUserEmail(),
          this.service.getOperatorCognitoData(this.userEmail),
        ]).subscribe(([currentUserEmail, operatorCognitoData]) => {
          this.showDisableBtn = currentUserEmail !== this.userEmail;
          this.isUserEnabled = operatorCognitoData.enabled;
        });
      }
    });

    this.route.queryParams.subscribe(params => {
      this.operatorId = params.key;
    });

    this.loadingStateService.isLoading.subscribe((loading) => {
      this.loading = loading;
    });

    this.service.getAllClients().subscribe((response) => {
      const clients = response?.clients || [];

      if (clients.length === 0) {
        this.snackBar.open(`No clients were obtained`, undefined, {
          duration: MODAL_DURATION,
          panelClass: 'warning-snackbar',
        });
      }

      const allSdrs = [];
      clients?.forEach((c) => {
        c.sdrs.forEach((sdr) => {
          allSdrs.push(sdr);
        });
      });

      clients?.unshift({
        name: '',
        sdrs: (allSdrs ?? []).filter((sdr) => !!sdr.email),
      });

      this.clientSdrs = clients.filter((client) => client.sdrs.some((sdr) => sdr.email !== null));
    });

    this.service.getAllSdrs().subscribe((data) => {
      this.allSdrs = (data ?? []).filter((sdr) => !!sdr.email);
    });
    this.loadingStateService.clearLoadingState();
  }

  ngAfterViewInit() {
    this.loadingStateService.setLoadingState(true);
    if (this.userEmail) {
      this.service.getUserData(this.userEmail).subscribe((userData) => {
        this.userId = userData.id;
        this.discrepancySdrs = userData.manual_discrepancies?.filter((x) => !!x.email) || [];
        this.reviewDiscrepancySdrs = userData.review_discrepancies?.filter((x) => !!x.email) || [];
        this.salesOperationsSdrs = userData.sales_operations?.filter((x) => !!x.email) || [];
        this.inboxReferralSdrs = userData.inbox_referral?.filter((x) => !!x.email) || [];
        this.discrepancySdrsDataSource = new MatTableDataSource(
          this.discrepancySdrs.map((item) => {
            return {
              $key: item,
              sdr: item,
            };
          }),
        );

        this.reviewDiscrepancySdrsDataSource = new MatTableDataSource(
          this.reviewDiscrepancySdrs.map((item) => {
            return {
              $key: item,
              sdr: item,
            };
          }),
        );

        this.salesOperationsSdrsDataSource = new MatTableDataSource(
          this.salesOperationsSdrs.map((item) => {
            return {
              $key: item,
              sdr: item,
            };
          }),
        );

        this.inboxReferralSdrsDataSource = new MatTableDataSource(
          this.inboxReferralSdrs.map((item) => {
            return {
              $key: item,
              sdr: item,
            };
          }),
        );

        const userPermissions = userData.permissions.map((item) => {
          return {
            $key: item,
            permission: item,
          };
        });
        this.permissionListData = new MatTableDataSource(userPermissions);
        this.permissionListData.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'permission':
              return item.permission.name;
            default:
              return item[property];
          }
        };
        this.permissionListData.sort = this.permissionSort;
        this.permissionListData.paginator = this.permissionPaginator;
        this.permissionListData.filterPredicate = (data, filter) => {
          return this.permissionColumns.some((ele) => {
            return ele != 'actions' && data[ele].name.toLowerCase().indexOf(filter) != -1;
          });
        };
        this.service.getAllPermissions().subscribe((response) => {
          this.availablePermissions = response
            ?.filter((e) => !userData.permissions.find((perm) => perm.name === e.name))
            .sort();
          this.refreshPermissionOptions();
        });
      });
    }
    if (this.operatorId) {
      this.service.listSdrByOperator(this.operatorId).subscribe((userData) => {
        this.userFolderSdrs = userData.filter((x) => !!x.email) || [];
    
        this.sdrsListData = new MatTableDataSource(
          this.userFolderSdrs.map((item) => {
            return {
              $key: item.id, // Asigna el ID del usuario como clave
              sdr: item.email, // Mantiene el email como sdr
              customerName: item.customerName,
              isDefault: item.isDefault || false, 
              isManualDiscrepancy: item.isManualDiscrepancy || false,
              isReviewDiscrepancy: item.isReviewDiscrepancy || false,
              isSalesOperator: item.isSalesOperation || false
            };
          })
        );
      });
    }
    this.loadingStateService.setLoadingState(false);
  }

  loadSdrsList(): void {
    this.service.listSdrByOperator(this.operatorId).subscribe((userData) => {
      this.userFolderSdrs = userData.filter((x) => !!x.email) || [];
  
      this.sdrsListData = new MatTableDataSource(
        this.userFolderSdrs.map((item) => {
          return {
            $key: item.id, // Asigna el ID del usuario como clave
            sdr: item.email, // Mantiene el email como sdr
            customerName: item.customerName,
            isDefault: item.isDefault || false, 
            isManualDiscrepancy: item.isManualDiscrepancy || false,
            isReviewDiscrepancy: item.isReviewDiscrepancy || false,
            isSalesOperator: item.isSalesOperation || false
          };
        })
      );
    });
  }

  onPermissionSearchClear() {
    this.permissionSearchKey = '';
    this.applyPermissionFilter();
  }

  getSDR(email: string) {
    return this.allSdrs.find((sdr) => sdr.email === email);
  }

  applyPermissionFilter() {
    this.permissionListData.filter = this.permissionSearchKey.trim().toLowerCase();
  }

  addFolderSdr(sdrEmail: string) {
    const sdr = sdrEmail ? this.getSDR(sdrEmail) : null;
    if (sdr) {
      this.loadingStateService.setLoadingState(true);
      this.userFolderSdrs.push(sdr);

      this.service.assignSdr({ user_id: sdr.id, operator_id: this.userId, sdr_type: 'default' }).subscribe(
        (response) => {
          this.loadingStateService.setLoadingState(false);
          if (response.code === 200) {
            this.sdrsListData = new MatTableDataSource(
              this.userFolderSdrs.map((sdr) => {
                return {
                  $key: sdr,
                  sdr: sdr,
                };
              }),
            );
            this.snackBar.open(`${sdr?.email} assigned successfully.`, undefined, { duration: MODAL_DURATION });
          } else {
            this.handleOperationError(response.error);
          }
        },
        (error) => {
          console.log(error);
          this.handleOperationError('An error ocurred while assigning the sdr');
        },
      );
    }
  }

  removeFolderSdr(sdr: ISdr) {
    this.loadingStateService.setLoadingState(true);
    this.userFolderSdrs.splice(
      this.userFolderSdrs.findIndex((item) => item.email === sdr.email),
      1,
    );
    this.service.unassignSdr(this.userId, sdr.id).subscribe(
      (response) => {
        this.loadingStateService.setLoadingState(false);
        this.snackBar.open(`${sdr.email} was successfully unassigned.`, undefined, { duration: MODAL_DURATION });
       
      },
      (error) => {
        console.log(error);
        this.handleOperationError('An error ocurred while removing the sdr.');
      },
    );
  }

  removePermission(permission: { name: string; id: number }) {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Are you sure you want to unassign this permission?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loadingStateService.setLoadingState(true);
        this.service.removePermission(this.userId, permission.id).subscribe(
          (response) => {
            this.loadingStateService.setLoadingState(false);
            this.permissionListData.data.splice(
              this.permissionListData.data.findIndex((item) => item.permission.name === permission.name),
              1,
            );
            this.availablePermissions.push(permission);
            this.availablePermissions.sort();
            this.refreshPermissionOptions();
            this.permissionListData._updateChangeSubscription();
            this.snackBar.open(`Permission ${permission.name} unassigned successfully`, undefined, {
              duration: MODAL_DURATION,
            });
          },
          (error) => {
            console.log(error);
            this.handleOperationError('An error ocurred while unassigning permission.');
          },
        );
      }
    });
  }

  addPermission() {
    const permission = this.selectedPermission
      ? this.availablePermissions.find((perm) => perm.name === this.selectedPermission)
      : null;

    if (permission) {
      const confirmDialogConfig = new MatDialogConfig();
      confirmDialogConfig.data = {
        title: 'Confirm',
        message: 'Are you sure you want to assign this permission?',
      };
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
      confirmDialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.loadingStateService.setLoadingState(true);
          this.service.addPermission(this.userId, permission.id).subscribe(
            (response) => {
              this.loadingStateService.setLoadingState(false);
              if (response.code === 200) {
                this.permissionListData.data.push({
                  $key: permission.name,
                  permission: permission,
                });
                this.availablePermissions.splice(
                  this.availablePermissions.findIndex((item) => item.name === permission.name),
                  1,
                );

                this.refreshPermissionOptions();

                this.permissionListData._updateChangeSubscription();
                this.snackBar.open(`Permission ${permission.name} assigned successfully`, undefined, {
                  duration: MODAL_DURATION,
                });
              } else {
                this.handleOperationError(response.error);
              }
              this.selectedPermission = '';
            },
            (error) => {
              console.log(error);
              this.handleOperationError('An error ocurred while assigning the permission');
              this.selectedPermission = '';
            },
          );
        }
      });
    }
  }

  onPasswordClear() {
    this.newPassword = '';
  }

  setPassword() {
    if (this.newPassword != '') {
      const confirmDialogConfig = new MatDialogConfig();
      confirmDialogConfig.data = {
        title: 'Confirm',
        message: 'Are you sure you want to set this new password?',
      };
      const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
      confirmDialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.loadingStateService.setLoadingState(true);
          this.service.changePassword(this.userEmail!, this.newPassword).subscribe(
            (response) => {
              this.loadingStateService.setLoadingState(false);
              if (response.success) {
                this.snackBar.open(`The new password for ${this.userEmail} was set successfully.`, undefined, {
                  duration: MODAL_DURATION,
                });
              } else {
                this.handleOperationError(response.error);
              }
              this.newPassword = '';
            },
            (error) => {
              console.log(error);
              this.handleOperationError('An error ocurred while setting the new password.');
              this.newPassword = '';
            },
          );
        } else {
          this.newPassword = '';
        }
      });
    }
  }

  handleOperationError(message: string) {
    this.loadingStateService.setLoadingState(false);
    const errorConfirmDialogConfig = new MatDialogConfig();
    errorConfirmDialogConfig.data = {
      title: 'Error',
      message,
    };
    this.dialog.open(AlertDialogComponent, errorConfirmDialogConfig);
  }

  refreshPermissionOptions() {
    this.filteredPermissionOptions = this.permissionFilterControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value?.name)),
      map((name) => (name ? this._filterPermission(name) : this.availablePermissions.slice())),
    );
  }

  displayFn(inputStr: string): string {
    return inputStr ? inputStr : '';
  }

  onAssignDiscrepancySdr(sdrEmail: string) {
    const sdr = this.getSDR(sdrEmail);
    this.loadingStateService.setLoadingState(true);
    this.discrepancySdrs.push(sdr!);
    this.service.assignSdr({ user_id: sdr!.id, operator_id: this.userId, sdr_type: 'manual_discrepancies' }).subscribe(
      (response) => {
        if (response.code === 200) {
          this.discrepancySdrsDataSource = new MatTableDataSource(
            this.discrepancySdrs.map((sdr) => {
              return {
                $key: sdr,
                sdr: sdr,
              };
            }),
          );
          this.snackBar.open(`${sdr!.email} assigned successfully.`, undefined, { duration: MODAL_DURATION });
        } else {
          this.handleOperationError(response.error);
        }
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while assigning sdr.');
      },
    );
  }

  onRemoveDiscrepancySdr(sdr: ISdr) {
    this.loadingStateService.setLoadingState(true);
    this.discrepancySdrs = this.discrepancySdrs.filter((currSdr) => currSdr.email !== sdr.email);
    this.service.removeSdr({ user_id: sdr.id, operator_id: this.userId, sdr_type: 'manual_discrepancies' }).subscribe(
      (response) => {
        this.discrepancySdrsDataSource = new MatTableDataSource(
          this.discrepancySdrs.map((sdr) => {
            return {
              $key: sdr,
              sdr: sdr,
            };
          }),
        );
        this.snackBar.open(`${sdr.email} was successfully unassigned`, undefined, { duration: MODAL_DURATION });
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while unassigning sdr');
      },
    );
  }

  onAssignReviewDiscrepancySdr(sdrEmail: string) {
    const sdr = this.getSDR(sdrEmail);
    this.loadingStateService.setLoadingState(true);
    this.reviewDiscrepancySdrs.push(sdr!);
    this.service.assignSdr({ user_id: sdr!.id, operator_id: this.userId, sdr_type: 'review_discrepancies' }).subscribe(
      (response) => {
        if (response.code === 200) {
          this.reviewDiscrepancySdrsDataSource = new MatTableDataSource(
            this.reviewDiscrepancySdrs.map((sdr) => {
              return {
                $key: sdr,
                sdr: sdr,
              };
            }),
          );
          this.snackBar.open(`${sdr!.email} assigned successfully.`, undefined, { duration: MODAL_DURATION });
        } else {
          this.handleOperationError(response.error);
        }
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while assigning sdr.');
      },
    );
  }

  onRemoveReviewDiscrepancySdr(sdr: ISdr) {
    this.loadingStateService.setLoadingState(true);
    this.reviewDiscrepancySdrs = this.reviewDiscrepancySdrs.filter((currSdr) => currSdr.email !== sdr.email);

    this.service.removeSdr({ user_id: sdr.id, operator_id: this.userId, sdr_type: 'review_discrepancies' }).subscribe(
      (response) => {
        this.reviewDiscrepancySdrsDataSource = new MatTableDataSource(
          this.reviewDiscrepancySdrs.map((sdr) => {
            return {
              $key: sdr,
              sdr: sdr,
            };
          }),
        );
        this.snackBar.open(`${sdr.email} was successfully unassigned.`, undefined, { duration: MODAL_DURATION });
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while unassigning sdr.');
      },
    );
  }

  onAssignSalesOperationsSdr(sdrEmail: string) {
    const sdr = this.getSDR(sdrEmail);
    this.loadingStateService.setLoadingState(true);
    this.salesOperationsSdrs.push(sdr!);
    this.service.assignSdr({ user_id: sdr!.id, operator_id: this.userId, sdr_type: 'sales_operator' }).subscribe(
      (response) => {
        if (response.code === 200) {
          this.salesOperationsSdrsDataSource = new MatTableDataSource(
            this.salesOperationsSdrs.map((sdr) => {
              return {
                $key: sdr,
                sdr: sdr,
              };
            }),
          );
          this.snackBar.open(`${sdr!.email} assigned successfully.`, undefined, { duration: MODAL_DURATION });
        } else {
          this.handleOperationError(response.error);
        }
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while assigning sdr.');
      },
    );
  }

  onAssignInboxReferralSdr(sdrEmail: string) {
    const sdr = this.getSDR(sdrEmail);
    this.loadingStateService.setLoadingState(true);
    this.inboxReferralSdrs.push(sdr!);
    this.service.assignSdr({ user_id: sdr!.id, operator_id: this.userId, sdr_type: 'inbox_referral' }).subscribe(
      (response) => {
        if (response.code === 200) {
          this.inboxReferralSdrsDataSource = new MatTableDataSource(
            this.inboxReferralSdrs.map((sdr) => {
              return {
                $key: sdr,
                sdr: sdr,
              };
            }),
          );
          this.snackBar.open(`${sdr!.email} assigned successfully.`, undefined, { duration: MODAL_DURATION });
        } else {
          this.handleOperationError(response.error);
        }
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while assigning sdr.');
      },
    );
  }

  onRemoveSalesOperationsSdr(sdr: ISdr) {
    this.loadingStateService.setLoadingState(true);
    this.salesOperationsSdrs = this.salesOperationsSdrs.filter((currSdr) => currSdr.email !== sdr.email);

    this.service.removeSdr({ user_id: sdr.id, operator_id: this.userId, sdr_type: 'sales_operator' }).subscribe(
      (response) => {
        this.salesOperationsSdrsDataSource = new MatTableDataSource(
          this.salesOperationsSdrs.map((sdr) => {
            return {
              $key: sdr,
              sdr: sdr,
            };
          }),
        );
        this.snackBar.open(`${sdr.email} was successfully unassigned.`, undefined, { duration: MODAL_DURATION });
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while unassigning sdr.');
      },
    );
  }

  onRemoveInboxReferralSdr(sdr: ISdr) {
    this.loadingStateService.setLoadingState(true);
    this.inboxReferralSdrs = this.inboxReferralSdrs.filter((currSdr) => currSdr.email !== sdr.email);

    this.service.removeSdr({ user_id: sdr.id, operator_id: this.userId, sdr_type: 'inbox_referral' }).subscribe(
      (response) => {
        this.inboxReferralSdrsDataSource = new MatTableDataSource(
          this.inboxReferralSdrs.map((sdr) => {
            return {
              $key: sdr,
              sdr: sdr,
            };
          }),
        );
        this.snackBar.open(`${sdr.email} was successfully unassigned.`, undefined, { duration: MODAL_DURATION });
        this.loadingStateService.setLoadingState(false);
      },
      (error) => {
        this.handleOperationError('An error ocurred while unassigning sdr.');
      },
    );
  }

  isPermissionValid() {
    return this.availablePermissions?.some((permission) => this.selectedPermission === permission.name);
  }

  onDisableUser() {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.data = {
      title: 'Confirm',
      message: 'Do you want to disable this user?',
    };
    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, confirmDialogConfig);
    confirmDialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.loadingStateService.setLoadingState(true);
        this.service.disableUser(this.userEmail!).subscribe(
          (response) => {
            this.loadingStateService.setLoadingState(false);
            if (response.code === 200) {
              this.isUserEnabled = false;
              this.snackBar.open(`User ${this.userEmail} was successfully disabled.`, undefined, {
                duration: MODAL_DURATION,
              });
            } else {
              this.handleOperationError(response.error);
            }
          },
          (error) => {
            console.log(error);
            this.handleOperationError('An error ocurred while disabling user');
          },
        );
      }
    });
  }

  private _filterPermission(permission: string): { name: string; id: number }[] {
    const filterValue = permission.toLowerCase();

    return this.availablePermissions.filter((e) => e.name.toLowerCase().includes(filterValue));
  }
}
