<div class="search-leads">
  <p class="search-leads__title">Interactions</p>
  <app-mail-classifier-search-interactions [isLoading]="isLoading"></app-mail-classifier-search-interactions>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="isLoading"
  ></mat-progress-bar>
  <ng-container *ngIf="searchResult?.interactionsData?.interactions?.length && !isLoading; else EmptyResults">
    <div class="search-leads__search-interactions-container">
      <mat-accordion class="search-leads__interactions">
        <mat-expansion-panel disabled>
          <mat-expansion-panel-header ngClass="search-leads__main-header">
            <div class="search-leads__interaction-header search-leads__interaction-header--bold">
              <span class="search-leads__header-status">Status</span>
              <span class="search-leads__header-created-date">Date Time Created</span>
              <span class="search-leads__header-interaction-date">Date Time Interaction</span>
              <span class="search-leads__header-forwarder">Forwarder ID</span>
              <span class="search-leads__header-campaing">Campaing ID</span>
              <span class="search-leads__header-template">Template ID</span>
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
        <mat-expansion-panel
          *ngFor="let interaction of searchResult?.interactionsData?.interactions"
          (opened)="setSelectedInteraction(interaction)"
        >
          <mat-expansion-panel-header>
            <div
              class="search-leads__interaction-header search-leads__interaction-header--light"
              [ngClass]="{
                'search-leads__interaction-header--selected':
                  selectedInteraction?.interactionId === interaction.interactionId,
              }"
            >
              <span class="search-leads__header-status">{{ interaction.prospectStatus }}</span>
              <span class="search-leads__header-created-date">{{
                interaction.utcDatetimeCreated | date: 'YYYY-MM-dd h:mm a'
              }}</span>
              <span class="search-leads__header-interaction-date">{{
                interaction.utcDatetimeInteraction | date: 'YYYY-MM-dd h:mm a'
              }}</span>
              <span class="search-leads__header-forwarder">{{ interaction?.forwardedContactId }}</span>
              <span class="search-leads__header-campaing">{{ interaction?.campaignId || '-' }}</span>
              <span class="search-leads__header-template">{{ interaction?.templateId || '-' }}</span>
            </div>
          </mat-expansion-panel-header>
          <app-mail-classifier-lead-interaction-item
            [contact]="searchResult.interactionsData.contact"
            [company]="searchResult.companyData"
            [interaction]="interaction"
          ></app-mail-classifier-lead-interaction-item>
          <div class="search-leads__actions">
            <button
              *ngIf="interaction.prospectStatus !== classificationStatus.Sent"
              mat-stroked-button
              (click)="onClickUndoInteraction(interaction)"
            >
              UNDO
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
  <ng-template #EmptyResults>
    <div class="search-leads__empty-results-container">
      <ng-container *ngIf="noResultsMessage; else InitialEmptyState"
        ><small>{{ noResultsMessage }}</small></ng-container
      >
      <ng-template #InitialEmptyState>
        <img
          src="/assets/images/empty_discrepancies_search_results.svg"
          alt="Search for Discrepancies"
        />
      </ng-template>
    </div>
  </ng-template>
</div>
