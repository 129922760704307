<div
  class="classifier-log"
  *ngIf="message && message.classifierLogId"
>
  <div class="classifier-log__heading">
    <div class="classifier-log__info">
      <div class="classifier-log__log-id">
        <span
          >SDR ID:<strong>{{ message.sdrId }}</strong></span
        >
      </div>
      <div class="classifier-log__sdr-id">
        <span
          >Classifier ID:<strong>{{ message.classifierLogId }}</strong></span
        >
      </div>
      <div class="classifier-log__sdr-email">
        <span
          >Email: &nbsp;<strong>{{ message.sdrMail }}</strong></span
        >
      </div>
    </div>
    <div
      *ngIf="false"
      class="classifier-log__folders"
    >
      <div class="classifier-log__input-folder">
        <span>Input Folder:</span>
        <app-mail-classifier-log-folder [folderType]="message.inputFolder"></app-mail-classifier-log-folder>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="classifier-log__output-folder">
        <span>Output Folder:</span>
        <app-mail-classifier-log-folder
          *ngIf="message?.outputFolder"
          [folderType]="message.outputFolder"
        ></app-mail-classifier-log-folder>
      </div>
    </div>
  </div>

  <div class="classifier-log__body">
    <ng-container *ngIf="message.email; else noRegister">
      <div class="classifier-log__table">
        <div class="classifier-log__table-header">
          <span class="classifier-log__prospect-id">ID</span>
          <span class="classifier-log__from-name">Name</span>
          <span class="classifier-log__from-mail">Email</span>
          <span class="classifier-log__status"></span>
        </div>
        <div class="classifier-log__table-row">
          <span
            class="classifier-log__prospect-id"
            (click)="onClickProspectId(message.prospectId)"
          ></span>
          <span class="classifier-log__from-name">{{ message.email.fromName }}</span>
          <span class="classifier-log__from-mail">{{ message.email.fromMail }}</span>
          <span class="classifier-log__status">{{ message.statusName | titlecase }}</span>
        </div>
        <div class="classifier-log__table-row">
          <span
            class="classifier-log__prospect-id clickable"
            (click)="onClickProspectId(message.prospectId)"
          >
            {{ message.prospectId && message.prospectId !== 0 ? message.prospectId : '' }}</span
          >
          <span class="classifier-log__from-name">
            {{ message?.prospectData?.fullName }}&nbsp;
            @if (message.originalContactFound === 'FOUND') {
              <div class="classifier-log__tag classifier-log__tag--found">Found</div>
            } @else {
              <div class="classifier-log__tag classifier-log__tag--not-found">Not Found</div>
            }
          </span>
          <span
            class="classifier-log__from-mail"
            (click)="onClickEmail(message?.prospectData?.emailAddress)"
          >
            {{ message?.prospectData?.emailAddress }}</span
          >
          <span class="classifier-log__status">In Contacts</span>
        </div>
        <div class="classifier-log__table-row">
          <span
            class="classifier-log__prospect-id clickable"
            (click)="onClickProspectId(message.prospectId)"
          >
            {{
              message?.originalContactData && message?.originalContactId !== 0 ? message.originalContactId : ''
            }}</span
          >
          <span class="classifier-log__from-name">
            {{ message?.originalContactData?.fullName }}&nbsp;
            @if (message.originalContactFound === 'FOUND') {
              <div class="classifier-log__tag classifier-log__tag--found">Found</div>
            } @else {
              <div class="classifier-log__tag classifier-log__tag--not-found">Not Found</div>
            }
          </span>
          <span
            class="classifier-log__from-mail"
            (click)="onClickEmail(message?.originalContactData?.emailAddress)"
          >
            {{ message?.originalContactData?.emailAddress }}</span
          >
          <span class="classifier-log__status">Original Contact Sent</span>
        </div>
      </div>
    </ng-container>
    <ng-template #noRegister>
      <div class="tag tag--not-found">Not Registers Found</div>
    </ng-template>
  </div>
  <div class="classifier-log__buttons-container">
    <button
      mat-stroked-button
      color="primary"
      class="classifier-log__view-contact-btn"
      (click)="onClickViewLead()"
      [disabled]="isContactDialogOpen$ | async"
    >
      <span>{{ !searchResult?.interactionsData?.contact ? 'Find Contact' : 'View Contact' }}</span>
    </button>
    <button
      class="classifier-log__complete-btn"
      mat-raised-button
      color="primary"
      (click)="onComplete()"
      [disabled]="isLoading"
    >
      {{ 'Complete Case' }}
    </button>
  </div>
</div>
