
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { SdApiService } from '../../../../services/base/sd-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-userlist',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements AfterViewInit  {

  constructor(private service: SdApiService, private router: Router) { }

  listData: MatTableDataSource<any> = null;
  displayedColumns: string[] = ['email', 'actions'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  searchKey: string;

  ngAfterViewInit() {
    this.service.getAllUser().subscribe(
      list => {
        const array = list.map(item => {
          return {
            $key: item.id,
            email: item.email
          };
        });
        this.listData = new MatTableDataSource(array);
        this.listData.sort = this.sort;
        this.listData.paginator = this.paginator;
        this.listData.filterPredicate = (data, filter) => {
          return this.displayedColumns.some(ele => {
            return ele != 'actions' && data[ele].toLowerCase().indexOf(filter) != -1;
          });
        };
      });
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }

  applyFilter() {
    if (!this.listData) return;
    this.listData.filter = this.searchKey.trim().toLowerCase();
  }

  navigateToDetails(row) {
    this.router.navigate(['/home/management/users', row.email], {
      queryParams: { key: row.$key }
    });
  }

  navigateToNew() {
    this.router.navigate(['/home/management/users/new']);
  }

}
