import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Contact } from '../../../../model/common/contact.model';
import { ProspectInfo } from '../../../../model/common/prospect-info.model';
import { ClassifierMessage } from '../../../../model/mail-classifier/classifier-message.model';
import { ContactDialogComponent } from '../../../../screens/shared/contact-dialog/contact-dialog.component';
import { LoadingStateService } from '../../../../services/base/loading-state.service';
import { LeadService } from '../../../../services/lead.service';
import { MailReviewService } from '../../../../services/mail-review.service';

@Component({
  selector: 'app-mail-classifier-log',
  templateUrl: './mail-classifier-log.component.html',
  styleUrls: ['./mail-classifier-log.component.scss'],
  standalone: false,
})
export class MailClassifierLogComponent implements OnInit, OnDestroy {
  @Input() message: ClassifierMessage;
  @Output() completeCase: EventEmitter<null> = new EventEmitter();
  @Output() emailClicked: EventEmitter<string> = new EventEmitter();
  isLoading = false;
  searchResult: ProspectInfo = null;
  currentContact: Contact = null;
  currentMessage: ClassifierMessage = null;
  isContactDialogOpen$ = combineLatest([this.mailReviewService.isContactDialogOpen$]).pipe(
    map(([isContactDialogOpen]) => {
      return isContactDialogOpen;
    }),
  );
  private destroy$ = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    private mailReviewService: MailReviewService,
    private leadService: LeadService,
    private loadingStateService: LoadingStateService,
  ) {}

  ngOnInit(): void {
    this.loadingStateService.isLoading.pipe(takeUntil(this.destroy$)).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });

    this.contact$();
    this.searchResult$();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  contact$() {
    this.mailReviewService.currrentContact$.pipe(takeUntil(this.destroy$)).subscribe((contact) => {
      this.currentContact = contact;
    });
  }

  searchResult$() {
    this.mailReviewService.currentClassifierMessage$.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.currentMessage = null;
      this.searchResult = null;

      if (message) {
        this.currentMessage = message;
        const prospectInfo: ProspectInfo = {
          interactionsData: message?.interactionsData || null,
          companyData: message?.companyData || null,
        };

        this.searchResult = Object.values(prospectInfo).every((value) => value) ? prospectInfo : null;
      }
    });
  }

  onClickViewLead() {
    this.dialog
      .open(ContactDialogComponent, {
        minHeight: '530px',
        maxWidth: 'initial',
        panelClass: 'view-lead-dialog',
        hasBackdrop: false,
        data: {
          emailAddress: this.currentContact?.emailAddress,
          contactId: this.currentContact?.contactId,
          customerId: this.currentMessage?.customerId,
          sdrId: this.currentMessage?.sdrId,
          messageId: this.currentMessage?.email?.messageId,
          showActions: true,
        },
      })
      .beforeClosed()
      .subscribe(() => {
        this.leadService.setIsCreatingContact(false);
      });
  }

  onComplete() {
    this.completeCase.emit();
  }

  onClickEmail(email: string) {
    this.emailClicked.emit(email);
  }

  onClickProspectId(prospectId: number): void {
    this.mailReviewService.setClickedProspectId(prospectId);
  }
}
