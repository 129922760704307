import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, finalize, takeUntil } from 'rxjs/operators';
import { ProspectInfoDto } from '../../../../model/common/prospect-info.dto';
import { ProspectInfo } from '../../../../model/common/prospect-info.model';
import { Lead } from '../../../../model/lead/lead.model';
import { ClassifierMessage } from '../../../../model/mail-classifier/classifier-message.model';
import { LoadingStateService } from '../../../../services/base/loading-state.service';
import { InteractionService } from '../../../../services/interaction.service';
import { LeadService } from '../../../../services/lead.service';
import { MailReviewService } from '../../../../services/mail-review.service';
import { SnackBarService } from '../../../../services/snackbar/snackbar.service';

@Component({
  selector: 'app-contact-search',
  templateUrl: './contact-search.component.html',
  styleUrls: ['./contact-search.component.scss'],
})
export class ContactSearchComponent implements OnInit, OnDestroy {
  @Input() initialEmailAddress: string;
  @Input() customerId: number;
  @Output() updateContactIdFromCtrl: EventEmitter<any> = new EventEmitter();
  emailCtrl = new FormControl('', [Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
  contactIdCtrl = new FormControl(null, [Validators.pattern('^[0-9]*$')]);
  isSearching = false;
  searchResult: ProspectInfo = null;
  noResultsMessage = '';
  lead: Lead;
  clientFilter = false;
  currentMessage: ClassifierMessage = null;
  private destroy$ = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    private leadService: LeadService,
    private loadingStateService: LoadingStateService,
    private mailReviewService: MailReviewService,
    private snackBarService: SnackBarService,
    private interactionService: InteractionService,
  ) {}

  ngOnInit() {
    this.emailCtrl.setValue(this.initialEmailAddress);

    this.loadingStateService.isLoading.pipe(takeUntil(this.destroy$)).subscribe((isLoading) => {
      this.isSearching = isLoading;
    });

    this.mailReviewService.clickedEmailToSearch$.pipe(takeUntil(this.destroy$)).subscribe((email) => {
      if (email) {
        this.emailCtrl.setValue(email);
        this.search();
      }
    });

    this.mailReviewService.currentClassifierMessage$.pipe(takeUntil(this.destroy$)).subscribe((currentMessage) => {
      this.currentMessage = currentMessage;
    });

    combineLatest([this.mailReviewService.clickedProspectId$, this.leadService.showAlias$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([prospectId, showAlias]) => {
        if (prospectId && !showAlias) {
          this.contactIdCtrl.setValue(prospectId);
          this.search();
        }
      });

    this.emailCtrl.valueChanges.subscribe(() => {
      if (this.emailCtrl.value) {
        this.contactIdCtrl.setValue('');
      }
    });

    this.contactIdCtrl.valueChanges.subscribe(() => {
      if (this.contactIdCtrl.value) {
        this.emailCtrl.setValue(null);
      }
    });

    this.leadService.currentContactId$.pipe(takeUntil(this.destroy$)).subscribe((contactId) => {
      this.contactIdCtrl.setValue(contactId);
      contactId && this.search();
    });

    combineLatest([this.leadService.reloadLeadForAlias$, this.leadService.currentLead$])
      .pipe(takeUntil(this.destroy$), debounceTime(500))
      .subscribe(([reload, lead]) => {
        if (reload && lead) {
          const contactId = lead?.idLead;
          this.getLeadByContactId(contactId);
        }
      });
  }

  ngOnDestroy(): void {
    this.mailReviewService.setClickedProspectId(null);
    this.mailReviewService.setClickedEmail('');
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getLeadByEmail() {
    const email = this.emailCtrl.value.trim();

    this.loadingStateService.setLoadingState(true);
    this.leadService.setCurrentLead(null);
    this.lead = null;

    this.leadService
      .getLeadByEmail(email)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe({
        next: (lead) => {
          if (!lead) {
            this.snackBarService.showWarning('No contact found with the provided email address.');
            return;
          }
          this.lead = lead;
          this.updateContactIdFromCtrl.emit(lead?.idLead);
          this.getInteractions(lead?.idLead);
          this.getForwarders(lead?.idLead);
        },
        error: () => {
          this.snackBarService.showError('An error occurred while searching for the contact.');
        },
      });
  }

  getForwarders(contactId: number) {
    const sdrId = this.currentMessage?.sdrId;

    if (!sdrId || !contactId) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to get the forwarders ',
      );
      return;
    }

    this.interactionService.getForwarders(contactId, sdrId).subscribe({
      next: (forwarders) => {
        this.interactionService.setForwarders(forwarders);
      },
      error: () => {
        this.snackBarService.showError('An error occurred while getting forwarders');
        this.interactionService.setForwarders([]);
      },
    });
  }

  getInteractions(contactId: number) {
    let prospectInfoDto: ProspectInfoDto;
    if (this.clientFilter) {
      prospectInfoDto = {
        contactId: contactId,
        customerId: this.customerId,
      };
    } else {
      prospectInfoDto = {
        contactId: contactId,
      };
    }
    this.interactionService
      .getFullProspectInfo(prospectInfoDto)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe({
        next: (response: ProspectInfo) => {
          this.searchResult = response || null;
          this.interactionService.setHasInteractions(response?.interactionsData?.interactions?.length > 0);
          this.noResultsMessage = response ? '' : "We couldn't find any results matching the search criteria";
          this.updateData();
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting contact');
        },
      });
  }

  updateData() {
    if (this.lead) {
      this.lead.company = this.searchResult?.companyData || null;
      const array = this.searchResult?.interactionsData?.interactions?.map((item) => {
        return {
          $key: item.interactionId,
          leadToCampaignId: item.interactionId,
          campaignId: item.campaignId,
          campaignBulkId: item.interactionId,
          campaignBulkDate: item.utcDatetimeInteraction,
          customerName: item.customerName,
          userId: item.sdrId,
          userEmail: item.sdrEmailAddress,
          emailStatus: item.prospectStatus,
          dateStatus: item.utcDatetimeCreated,
          customerId: item.customerId,
          sdrId: item.sdrId,
          prospectStatus: item.prospectStatus,
          utcDateTimeInteraction: item.utcDatetimeInteraction,
          sdrEmailAddress: item.sdrEmailAddress,
          interactionId: item.interactionId,
        };
      });
      this.lead.history = array;
    }
    this.leadService.setCurrentLead(this.lead || null);
  }

  getLeadByContactId(contactId: number) {
    this.leadService.setReloadLeadForAlias(false);
    this.loadingStateService.setLoadingState(true);
    this.leadService.setCurrentLead(null);
    this.lead = null;

    this.leadService
      .getLeadByContactId(contactId)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loadingStateService.setLoadingState(false)),
      )
      .subscribe({
        next: (lead) => {
          if (!lead) {
            this.snackBarService.showWarning('No contact found with the provided contactId.');
            return;
          }
          this.lead = lead;
          this.updateContactIdFromCtrl.emit(contactId);
          this.getInteractions(contactId);
          this.getForwarders(contactId);
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting contact');
        },
      });
  }

  onClientFilterChange(event: boolean): void {
    this.clientFilter = event;
    this.search();
  }

  search() {
    if (this.emailCtrl.value && this.emailCtrl.value.trim() !== '') {
      this.getLeadByEmail();
    } else if (this.contactIdCtrl.value && this.contactIdCtrl.value) {
      this.getLeadByContactId(this.contactIdCtrl?.value);
    }
  }

  onEmailClear() {
    this.emailCtrl.reset('');
  }
}
