<div class="converted">
  <div
    cdkDrag
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle
    class="converted__header"
  >
    <span class="converted__title">Converted</span>
    <button
      mat-stroked-button
      (click)="toggleClientContactHistory = !toggleClientContactHistory"
      [disabled]="!clientContactHistoryData?.length"
    >
      {{ toggleClientContactHistory ? 'Hide Client Contact History' : 'Show Client Contact History' }}
    </button>
  </div>

  <div mat-dialog-content>
    <form
      class="converted__form"
      [formGroup]="convertedForm"
    >
      <mat-form-field
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>First Name</mat-label>
        <input
          matInput
          formControlName="firstName"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Middle Name</mat-label>
        <input
          matInput
          formControlName="middleName"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Last Name</mat-label>
        <input
          matInput
          formControlName="lastName"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Title</mat-label>
        <input
          matInput
          formControlName="jobTitle"
        />
      </mat-form-field>
      <mat-form-field
        *ngIf="clientRepresentatives?.useFullInteraction && !convertedDialogData?.isConvertedChannel"
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Job Posting</mat-label>
        <input
          matInput
          formControlName="jobPosting"
        />
      </mat-form-field>
      <mat-form-field
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Choose a Client</mat-label>
        <mat-select formControlName="salesRepId">
          <mat-option
            [value]="client.clientContactId"
            *ngFor="let client of clientRepresentatives?.representatives"
          >
            {{ client.name + ' | ' + client.geographicZone }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Forwarder</mat-label>
        <mat-icon
          class="converted-form__forwarder-icon"
          matSuffix
          [matMenuTriggerFor]="menu"
          *ngIf="forwarders?.length > 1"
          >person_search</mat-icon
        >
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            *ngFor="let forwarder of forwarders"
            (click)="setForwarder(forwarder.originalEmail)"
          >
            {{ forwarder.originalEmail }}
          </button>
        </mat-menu>
        <input
          matInput
          formControlName="comments"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="clientRepresentatives?.useFullInteraction"
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Choose a Source</mat-label>
        <mat-select
          formControlName="bdSourceId"
          [matTooltip]="bdsourceTooltip"
          matTooltipPosition="above"
          #source
        >
          <mat-option
            [value]="source.id"
            *ngFor="let source of leadGenSources"
          >
            {{ source.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="clientRepresentatives?.useFullInteraction && !convertedDialogData?.isConvertedChannel"
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Choose a Qualification</mat-label>
        <mat-select formControlName="bdMatrixId">
          <mat-option
            [value]="matrix.id"
            *ngFor="let matrix of catalogs?.matrix"
          >
            {{ matrix.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="clientRepresentatives?.useFullInteraction && !convertedDialogData?.isConvertedChannel"
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Choose a Country</mat-label>
        <mat-select formControlName="bdCountryId">
          <mat-option
            [value]="country.id"
            *ngFor="let country of catalogs?.country"
          >
            {{ country.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        *ngIf="clientRepresentatives?.useFullInteraction && !convertedDialogData?.isConvertedChannel"
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Choose a Delay</mat-label>
        <mat-select formControlName="bdDelayId">
          <mat-option
            [value]="delay.id"
            *ngFor="let delay of catalogs?.delay"
          >
            {{ delay.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        *ngIf="clientRepresentatives?.useFullInteraction && !convertedDialogData?.isConvertedChannel"
        appearance="outline"
        class="form-field-5"
        subscriptSizing="dynamic"
      >
        <mat-label>Choose an Industry</mat-label>
        <mat-select formControlName="bdIndustryId">
          <mat-option
            [value]="industry.id"
            *ngFor="let industry of catalogs?.industry"
          >
            {{ industry.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="score !== null">
        <span>Score: {{ score }}</span>
      </div>
    </form>

    <div
      *ngIf="toggleClientContactHistory && !this.convertedDialogData.isMessageClassification"
      class="converted__client-contact-history-container"
    >
      <app-client-contact-history [clientContactHistoryData]="clientContactHistoryData"></app-client-contact-history>
    </div>
  </div>
  <div mat-dialog-actions>
    <button
      mat-button
      mat-dialog-close
    >
      CANCEL
    </button>
    <button
      mat-button
      color="primary"
      (click)="handleConversion()"
      [disabled]="convertedForm.invalid || this.isSaving"
    >
      <mat-spinner
        *ngIf="this.isSaving"
        diameter="20"
      ></mat-spinner>
      <span *ngIf="!this.isSaving">{{ isNewInteraction ? 'SAVE' : 'UPDATE' }}</span>
    </button>
  </div>
</div>
