import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DatepickerDialogComponent } from '../../../components/datepicker-dialog/datepicker-dialog.component';
import { ClassificationStatusEnum } from '../../../model/common/classification-status.enum';
import { ClassificationStatus } from '../../../model/common/classification-status.model';
import { Contact } from '../../../model/common/contact.model';
import { ConvertedDialogData } from '../../../model/common/converted-dialog-data.model';
import { FunctionName } from '../../../model/common/function-name.enum';
import { Interaction } from '../../../model/common/interaction.model';
import { ServiceInfo } from '../../../model/common/service-info.model';
import { ServiceInstance } from '../../../model/common/service-instance.enum';
import { DatepickerDialogData } from '../../../model/datepicker-dialog/datepicker-dialog-data.model';
import { InteractionRequestDto } from '../../../model/interaction/interaction-request.dto';
import { ClassificationStatusData } from '../../../model/mail-classifier/classification-status-data.model';
import { ClassifyMessageDto } from '../../../model/sdr-conversations/classify-message.dto';
import { Message } from '../../../model/sdr-conversations/conversation-mesage.model';
import { Conversation } from '../../../model/sdr-conversations/sdrs-conversations.model';
import { LoadingStateService } from '../../../services/base/loading-state.service';
import { ClassifyMessageService } from '../../../services/classify-message.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { InteractionService } from '../../../services/interaction.service';
import { SdrConversationsService } from '../../../services/sdr-conversations.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { BREAKPOINT_1280, BREAKPOINT_1450, BREAKPOINT_1650 } from '../../../utils/constants';
import { ConvertedDialogComponent } from '../../shared/converted-dialog/converted-dialog.component';

@Component({
  selector: 'app-classification',
  templateUrl: './classification.component.html',
  styleUrls: ['./classification.component.scss'],
})
export class ClassificationComponent implements OnInit, OnDestroy {
  @ViewChild('overlayContent') overlayContent!: TemplateRef<any>;
  @ViewChild('toggleButton', { read: ViewContainerRef }) private buttonRef!: ViewContainerRef;
  statuses: ClassificationStatus[] = [];
  subscriptions = new Subscription();
  selectedInteraction: Interaction = null;
  selectedMessages: Message[] = null;
  selectedConversation: Conversation = null;
  isClassifying = false;
  currentContact: Contact = null;
  statusesToShow = 6;
  breakPointsNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [BREAKPOINT_1280, 'XSLarge'],
    [BREAKPOINT_1450, 'MLarge'],
    [BREAKPOINT_1650, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);
  breakpointValueMap = new Map([
    [this.breakPointsNameMap.get(Breakpoints.XSmall), 1],
    [this.breakPointsNameMap.get(Breakpoints.Small), 3],
    [this.breakPointsNameMap.get(Breakpoints.Medium), 5],
    [this.breakPointsNameMap.get(BREAKPOINT_1280), 3],
    [this.breakPointsNameMap.get(BREAKPOINT_1450), 4],
    [this.breakPointsNameMap.get(BREAKPOINT_1650), 4],
    [this.breakPointsNameMap.get(Breakpoints.XLarge), 5],
  ]);
  currentScreenSize: string;
  private destroy$: Subject<boolean> = new Subject();

  private overlayRef: OverlayRef | null = null;
  isOverlayOpen = false;

  constructor(
    private overlay: Overlay,
    private configurationService: ConfigurationService,
    private classifyMessageService: ClassifyMessageService,
    private interactionService: InteractionService,
    private loadingStateService: LoadingStateService,
    private dialog: MatDialog,
    private matDialog: MatDialog,
    private sdrConversationsService: SdrConversationsService,
    private snackBarService: SnackBarService,
  ) {
    inject(BreakpointObserver)
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        BREAKPOINT_1280,
        BREAKPOINT_1450,
        BREAKPOINT_1650,
        Breakpoints.XLarge,
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.breakPointsNameMap.get(query) ?? 'Unknown';
            this.statusesToShow = this.breakpointValueMap.get(this.currentScreenSize) || 0;
          }
        }
      });
  }

  ngOnInit(): void {
    this.loadStatuses();

    this.sdrConversationsService.selectedInteraction$.pipe(takeUntil(this.destroy$)).subscribe((interaction) => {
      this.selectedInteraction = interaction;
    });

    this.sdrConversationsService.selectedMessages$.pipe(takeUntil(this.destroy$)).subscribe((message) => {
      this.selectedMessages = message;
    });

    this.sdrConversationsService.currrentContact$.pipe(takeUntil(this.destroy$)).subscribe((contact) => {
      this.currentContact = contact;
    });

    this.sdrConversationsService.selectedConversation$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedConversation) => {
        this.selectedConversation = selectedConversation ? { ...selectedConversation } : null;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  loadStatuses() {
    this.configurationService
      .getClassificationStatuses()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response: ClassificationStatus[]) => {
        this.statuses = response?.length
          ? response.filter((x) => x.messagesPriority !== null).sort((a, b) => a.messagesPriority - b.messagesPriority)
          : [];
      });
  }

  handleClassification(event: Event, newStatus: ClassificationStatus) {
    event.stopPropagation();
    this.closeOverlay();
    if (!this.selectedInteraction || !this.selectedMessages?.length || !this.selectedConversation) return;

    if (newStatus.ruleDescription === ClassificationStatusEnum.Converted) {
      this.matDialog.open(ConvertedDialogComponent, {
        data: {
          ...this.getConvertedDialogData(newStatus),
        } as ConvertedDialogData,
        minWidth: '40vw',
      });
      return;
    }

    if (newStatus.ruleDescription === ClassificationStatusEnum.ToContact) {
      this.openDatePickerForContactClassification(newStatus);
      return;
    }

    this.handleClassifyMessage(newStatus);
  }

  openDatePickerForContactClassification(newStatus: ClassificationStatus) {
    const isRequired = newStatus.ruleDescription === ClassificationStatusEnum.ToContact ? true : false;
    const datePickerDialogConfig = new MatDialogConfig();
    datePickerDialogConfig.data = {
      required: isRequired,
      defaultDate: null,
    } as DatepickerDialogData;
    const datePickerDialogRef = this.dialog.open(DatepickerDialogComponent, datePickerDialogConfig);
    datePickerDialogRef.afterClosed().subscribe((date) => {
      if (date === false || date === undefined) {
        return;
      }

      if (!date && isRequired) {
        return;
      }

      this.handleToContactClassification(newStatus, date);
    });
  }

  handleToContactClassification(newStatus: ClassificationStatus, returnDateFromDialog?: string) {
    const returnDateValue = returnDateFromDialog;
    const formattedReturnDate = returnDateValue ? new Date(returnDateValue).toISOString().split('T')[0] : null;
    const { sentDate } = this.selectedMessages[0];
    const interactionRequestDto: InteractionRequestDto = {
      contactInteraction: {
        contactId: this.currentContact.contactId,
        interactionId: this.selectedInteraction.interactionId,
        messageId: this.selectedInteraction.messageId,
        sdrId: this.selectedInteraction.sdrId,
        interactionUtcDatetime: sentDate,
        ...(formattedReturnDate && { toContactDate: formattedReturnDate }),
        replyMessageId: this.selectedMessages?.[this.selectedMessages.length - 1]?.messageId,
      },
      messageHandlerId: this.selectedMessages?.[0]?.messageHandlerId,
      interactionType: this.selectedInteraction.interactionType,
    };

    const classification: ClassificationStatusData = {
      classificationId: newStatus.ruleId,
      classificationName: newStatus.ruleDescription,
    };

    if (
      !Object.values(interactionRequestDto).every((value) => value) ||
      !Object.values(classification).every((value) => value)
    ) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to run the classification.',
      );
      return;
    }

    this.classifyToContactMessage(interactionRequestDto, classification);
  }

  classifyToContactMessage(interactionRequestDto: InteractionRequestDto, classification: ClassificationStatusData) {
    const serviceInfo: ServiceInfo = {
      serviceInstance: ServiceInstance.InteractionService,
      functionName: FunctionName.ToContactMessage,
    };

    this.classifyMessageService.classification = classification;
    this.classifyMessageService.classifyMessage(serviceInfo, interactionRequestDto);
  }

  handleClassifyMessage(newStatus: ClassificationStatus) {
    const classifyMessageDto: ClassifyMessageDto = {
      ruleId: newStatus?.ruleId,
      messageHandlerIds: this.selectedMessages?.map((m) => m.messageHandlerId),
      contactId: this.currentContact?.contactId,
      sdrId: this.selectedInteraction?.sdrId,
      interactionId: this.selectedInteraction?.interactionId,
      interactionType: this.selectedInteraction?.interactionType,
    };

    const classification: ClassificationStatusData = {
      classificationId: newStatus.ruleId,
      classificationName: newStatus.ruleDescription,
    };

    if (
      !Object.values(classifyMessageDto).every((value) => value) ||
      !Object.values(classification).every((value) => value)
    ) {
      this.snackBarService.showError(
        'An error occurred while retrieving the information required to run the classification.',
      );
      return;
    }

    this.classifyMessage(classifyMessageDto, classification);
  }

  classifyMessage(classifyMessageDto: ClassifyMessageDto, classification: ClassificationStatusData) {
    const serviceInfo: ServiceInfo = {
      serviceInstance: ServiceInstance.SdrConversationService,
      functionName: FunctionName.ClassifyMessage,
    };

    this.classifyMessageService.classification = classification;
    this.classifyMessageService.classifyMessage(serviceInfo, classifyMessageDto);
  }

  private getConvertedDialogData(newStatus): ConvertedDialogData {
    const { ruleId, ruleDescription } = newStatus;
    const { sentDate } = this.selectedMessages[0];
    const messageHandlerIds = this.selectedMessages?.map((m) => m.messageHandlerId);
    const replyMessageId = this.selectedMessages?.[this.selectedMessages.length - 1]?.messageId;

    const convertedDialogData: ConvertedDialogData = {
      ruleId,
      ruleDescription,
      messageHandlerIds,
      isMessageClassification: true,
      interaction: this.selectedInteraction,
      contact: this.currentContact,
      replyMessageId,
      interactionUtcDatetime: sentDate,
    };
    return convertedDialogData;
  }

  toggleOverlay() {
    this.isOverlayOpen ? this.closeOverlay() : this.openOverlay();
  }

  openOverlay() {
    if (this.overlayRef) {
      return;
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.buttonRef.element)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
      ])
      .withPush(false)
      .withFlexibleDimensions(false);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
    });

    this.overlayRef.backdropClick().subscribe(() => this.closeOverlay());

    const templatePortal = new TemplatePortal(this.overlayContent, this.buttonRef);
    this.overlayRef.attach(templatePortal);
    this.isOverlayOpen = true;
  }

  closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.isOverlayOpen = false;
    }
  }
}
