import { AfterViewInit, Component, ElementRef, Inject, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-faq-operator',
  templateUrl: './faq-operator.component.html',
  styleUrls: ['./faq-operator.component.scss'],
})
export class FaqOperatorComponent implements OnInit, AfterViewInit {
  title: string;
  public htmlData: any;
  @ViewChild('faqIframe') iframe: ElementRef;
  doc: any;
  compRef: any;
  attachmentDynamicPadding = 0;
  screenHeight: number;
  screenWidth: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public parentData: any,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.htmlData = null;

    if (this.parentData.faqText) {
      this.htmlData = this.sanitizer.sanitize(
        SecurityContext.HTML,
        this.sanitizer.bypassSecurityTrustHtml(this.parentData.faqText),
      );
      this.setIframeReady(this.iframe, this.htmlData);
    } else {
      this.iframe.nativeElement.style.setProperty('display', 'none');
    }
    this.title = this.parentData.faqTitle ? this.parentData.faqTitle : 'Frequently Asked Questions';
    // Scroll to the top once the selected email changes
    const element: HTMLIFrameElement = document.getElementById('faqIframe') as HTMLIFrameElement;
    const iframe = element.contentWindow;
    iframe.document.querySelectorAll('*').forEach((n) => n.scrollTo(0, 0));
  }

  private setIframeReady(iframe: ElementRef, html: string): void {
    const win: Window = iframe.nativeElement.contentWindow;
    const doc: Document = win.document;
    doc.open();
    doc.write(html);
    doc.close();
    this.iframe.nativeElement.style.setProperty('display', 'inherit');
    this.iframe.nativeElement.style.setProperty(
      'height',
      this.iframe.nativeElement.contentWindow.document.body.scrollHeight + 'px',
    );
    this.iframe.nativeElement.style.setProperty('width', '95%');

    const htmlElement: HTMLIFrameElement = document.getElementById('faqIframe') as HTMLIFrameElement;
    const htmlIframe = htmlElement.contentWindow;
    htmlIframe.document.querySelectorAll('*').forEach((n) => {
      if (n.tagName == 'A') {
        n.setAttribute('target', '_blank');
      }
    });
  }
}
