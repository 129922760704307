<div class="classifier-toolbar">
  <div class="classifier-toolbar__left-content">
    <span class="classifier-toolbar__title">{{ isReview ? 'Review emails' : 'Manual emails' }}</span>
    <button
      matTooltip="Refresh"
      (click)="onRefresh()"
      id="reload-btn"
      class="classifier-toolbar__refresh-btn"
    >
      <mat-icon class="mails-feed-comp__refresh-icon">sync</mat-icon>
    </button>
  </div>
  <div class="classifier-toolbar__right-content">
    <div
      class="classifier-toolbar__pending"
      id="mail-count"
    >
      <mat-icon
        fontIcon="warning_amber"
        class="classifier-toolbar__pending-icon"
      ></mat-icon>
      Pending: <span class="classifier-toolbar__count">{{ pendingCount }}</span>
    </div>
    <button
      class="classifier-toolbar__navigation-btn"
      mat-button
      color="primary"
      (click)="onMovePrevious()"
      [disabled]="isPrevDisabled"
    >
      <mat-icon>navigate_before</mat-icon>
    </button>
    <button
      class="classifier-toolbar__navigation-btn classifier-toolbar__navigation-btn--next"
      mat-button
      color="primary"
      (click)="onMoveNext()"
      [disabled]="isNextDisabled"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>
