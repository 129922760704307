import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StatusScreen } from '../../../../model/common/status-screen.enum';
import { MailReviewService } from '../../../../services/mail-review.service';

@Component({
  selector: 'app-mail-classifier-toolbar',
  templateUrl: './mail-classifier-toolbar.component.html',
  styleUrls: ['./mail-classifier-toolbar.component.scss'],
  standalone: false,
})
export class MailClassifierToolbarComponent implements OnDestroy {
  @Input() pendingCount = 0;
  @Input() isNextDisabled = false;
  @Input() isPrevDisabled = false;
  @Output() navigateNext: EventEmitter<null> = new EventEmitter();
  @Output() navigatePrev: EventEmitter<null> = new EventEmitter();
  @Output() refreshMails: EventEmitter<null> = new EventEmitter();
  protected isReview = false;
  private destroy$ = new Subject<boolean>();

  constructor(private mailReviewService: MailReviewService) {
    this.mailReviewService.currentStatusScreen$.pipe(takeUntil(this.destroy$)).subscribe((statusScreen) => {
      this.isReview = statusScreen === StatusScreen.Review;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  onMoveNext() {
    this.navigateNext.emit();
  }

  onMovePrevious() {
    this.navigatePrev.emit();
  }

  onRefresh() {
    this.refreshMails.emit();
  }
}
