<mat-card class="mat-elevation-z2 top-navigation">
  <div class="top-navigation__left-content">
    <div class="mobile-only">
      <mat-icon
        (click)="toggleFeed()"
        class="top-navigation__mobileFeedToggle"
        >list</mat-icon
      >
    </div>
    <div>
      <img
        src="assets/images/logo.png"
        alt=""
        class="top-navigation__logo"
      />
    </div>
    <div
      class="top-navigation__button"
      (click)="navigateToHome()"
      id="navigate-home"
    >
      <mat-icon class="material-icons-outlined top-navigation__button-icon">home</mat-icon>
      Home
    </div>
    <div
      *ngIf="hasSdrConversationsPermission"
      class="top-navigation__button"
      (click)="navigateToConversations()"
      id="sdr-conversation-menu-item"
    >
      <mat-icon class="top-navigation__button-icon">mark_unread_chat_alt</mat-icon>
      SDR Conversations
    </div>
    <div
      *ngIf="hasDiscrepanciesPermission"
      class="top-navigation__button"
      (click)="navigateToMailReview()"
      id="mail-review-menu-item"
    >
      <mat-icon class="top-navigation__button-icon">contact_mail</mat-icon>
      Review Mails
    </div>
    <div
      *ngIf="hasDiscrepanciesPermission"
      class="top-navigation__button"
      (click)="navigateToMailManual()"
      id="mail-manual-menu-item"
    >
      <mat-icon class="top-navigation__button-icon">contact_mail</mat-icon>
      Manual Mails
    </div>
  </div>

  <div class="top-navigation__right-content">
    <a
      class="top-navigation__contact-container"
      target="_blank"
      href="https://bairesdev.atlassian.net/servicedesk/customer/portal/43/group/240"
    >
      <mat-icon class="material-symbols-outlined top-navigation__face-icon">face</mat-icon>
      <span class="top-navigation__contact-text">Contact Support</span>
    </a>

    <div
      class="top-navigation__account-container"
      [matMenuTriggerFor]="accountMenu"
    >
      <mat-icon class="material-symbols-outlined top-navigation__account-icon">account_circle</mat-icon>
      <span class="top-navigation__account-text">
        {{ userEmail }}
      </span>

      <mat-menu #accountMenu="matMenu">
        <button
          (click)="navigateToManagement()"
          *ngIf="isAdmin"
          mat-menu-item
          id="management-btn"
        >
          Management
        </button>
        <button
          (click)="logOut()"
          mat-menu-item
          id="sign-out-btn"
        >
          Sign out
        </button>
      </mat-menu>
    </div>
  </div>
</mat-card>
