<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoadingSdrsConversations"
></mat-progress-bar>

<mat-drawer-container
  *ngIf="!isLoadingSdrsConversations"
  class="drawer-container"
  autosize
>
  <mat-drawer
    #drawer
    mode="over"
    position="end"
    [ngClass]="'extra-info-sidenav'"
    ><div
      class="sdr-conversations__extra-info"
      *ngIf="selectedConversation && showExtraInfoSidenav"
    >
      <app-classification></app-classification>
      <app-extra-info></app-extra-info>
      <div class="sdr-conversations__interactions">
        <app-interactions></app-interactions>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="sdr-conversations">
      <div class="sdr-conversations__container">
        <div class="sdr-conversations__toolbar">
          <div class="sdr-conversations__selectable-container">
            <mat-form-field
              class="sdr-conversations__folder-picker form-field-5"
              appearance="outline"
              id="folder-mat-form-field"
              subscriptSizing="dynamic"
            >
              <mat-select
                id="folder-mat-select"
                [value]="selectedFolder"
                (selectionChange)="onSelectFolder($event.value)"
              >
                <mat-option
                  *ngFor="let folder of folders; let i = index"
                  [value]="folder.value"
                  [id]="'folder-mat-option-' + i"
                >
                  {{ folder.title | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field
              class="sdr-conversations__conversation-type-picker form-field-5"
              appearance="outline"
              id="conversation-type-mat-form-field"
              subscriptSizing="dynamic"
            >
              <mat-select
                id="conversation-type-mat-select"
                [value]="selectedConversationType"
                (selectionChange)="onSelectConversationType($event.value)"
              >
                <mat-option
                  *ngFor="let conversationType of conversationTypes; let i = index"
                  [value]="conversationType.value"
                  [id]="'conversation-type-mat-option-' + i"
                >
                  {{ conversationType.title | titlecase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="sdr-conversations__refresh-container">
            <button
              mat-icon-button
              matTooltip="Refresh"
              (click)="onRefreshConversations()"
            >
              <mat-icon>sync</mat-icon>
            </button>
          </div>
        </div>
        <div
          class="sdr-conversations__conversations-list"
          *ngIf="filteredSdrConversations?.length"
        >
          <div
            class="sdr-conversations__item"
            *ngFor="let sdrConversation of filteredSdrConversations; let i = index"
          >
            <span class="sdr-conversations__sdr-name">{{ sdrConversation.sdrName }}</span>
            <app-conversation
              [conversations]="sdrConversation.conversations"
              [selectedConversation]="selectedConversation"
            ></app-conversation>
          </div>
        </div>
      </div>
      <div
        class="sdr-conversations__messages"
        *ngIf="filteredSdrConversations?.length && selectedConversation"
      >
        <app-message></app-message>
      </div>
      <div
        class="sdr-conversations__extra-info"
        [ngClass]="{ 'sdr-conversations__extra-info--with-interactions': hasInteractions }"
        *ngIf="filteredSdrConversations?.length && selectedConversation! && !showExtraInfoSidenav"
      >
        <app-classification></app-classification>
        <app-extra-info></app-extra-info>
        <app-interactions></app-interactions>
      </div>
      <div
        class="sdr-conversations__sidenav-action-info"
        *ngIf="showExtraInfoSidenav && selectedConversation"
      >
        <mat-icon
          color="primary"
          (click)="drawer.toggle()"
          >chevron_left</mat-icon
        >
      </div>

      <div
        class="sdr-conversations__not-results"
        *ngIf="filteredSdrConversations?.length && !selectedConversation"
      >
        <mat-icon
          class="sdr-conversations__info-icon"
          color="primary"
          >info</mat-icon
        >
        Please select a conversation
      </div>
      <div
        class="sdr-conversations__not-results"
        *ngIf="!filteredSdrConversations?.length"
      >
        <mat-icon
          class="sdr-conversations__info-icon"
          color="warn"
          >info</mat-icon
        >
        No conversations found
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
