export enum ClassificationStatusEnum {
  Sent = 'Sent',
  ConvertedLead = 'Converted - Lead',
  Converted = 'Converted',
  ConvertedChannel = 'Converted Channel',
  Register = 'Register',
  Replied = 'Replied',
  Forwarded = 'Forwarded',
  Other = 'Other',
  PostConversionComplaint = 'PostConversion Complaint',
  BadFitCompany = 'Bad Fit Company',
  UnsubscribeCompany = 'Unsubscribe Company',
  Competitor = 'Competitor',
  Client = 'Client',
  DeleteFrom = 'Delete From',
  Inbox = 'Inbox',
  ClosedCompany = 'Closed CO.',
  AbuseReportCompany = 'Abuse Report CO.',
}
