import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs/Observable';
import { finalize, map, startWith } from 'rxjs/operators';
import { Source } from '../../../../model/common/source.enum';
import { SalesRepDialogData } from '../../../../model/sales-representative/sales-rep-dialog-data.model';
import { SalesRepresentative } from '../../../../model/SalesRepresentative';
import { SalesRepresentativeConnected } from '../../../../model/sdr-conversations/sales-rep-connected.model';
import { SdrConversationsService } from '../../../../services/sdr-conversations.service';
import { SnackBarService } from '../../../../services/snackbar/snackbar.service';
import { TemplateService } from '../../../../services/template.service';

@Component({
  selector: 'app-select-sales-representative',
  templateUrl: './select-sales-representative.component.html',
  styleUrls: ['./select-sales-representative.component.scss'],
})
export class SelectSalesRepresentativeComponent implements OnInit {
  @Output() closeDialog = new EventEmitter();
  protected isLoadingSalesRep = false;
  protected isLoadingSalesRepConnected = false;
  protected salesRepFilterControl = new FormControl();
  protected filteredSalesRepOptions: Observable<any[]>;
  protected filteredSalesRepConnectedOptions: Observable<any[]>;
  protected showSalesRepConnected = false;
  protected allSalesRepConnected: SalesRepresentativeConnected[] = [];
  private allSalesRepObj: SalesRepresentative[] = [];
  protected source = Source;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected data: SalesRepDialogData,
    private dialog: MatDialogRef<SelectSalesRepresentativeComponent>,
    private snackBarService: SnackBarService,
    private sdrConversationService: SdrConversationsService,
    private templateService: TemplateService,
  ) {}

  ngOnInit() {
    this.getSalesRepresentativesByClient();
    this.data?.source === Source.SendMessage && this.getSalesRepConnected();
  }

  get isValidSaleRep(): boolean {
    return this.salesRepFilterControl.valid;
  }

  protected async onShowSalesRepConnected() {
    this.showSalesRepConnected = !this.showSalesRepConnected;
    await this.handleClearSalesRepresentativeCtrl();

    this.salesRepFilterControl.addValidators((): ValidationErrors | null => {
      return this.showSalesRepConnected
        ? !this.getSaleRepConnected()
          ? { invalidSalesRep: true }
          : null
        : !this.getSaleRepProfileCC()
          ? { invalidSalesRep: true }
          : null;
    });

    this.salesRepFilterControl.updateValueAndValidity();
  }

  protected displayFn(inputStr: string): string {
    return inputStr ? inputStr : '';
  }

  protected onUseSalesRep() {
    this.dialog.close(this.showSalesRepConnected ? this.getSaleRepConnected() : this.getSaleRepProfileCC());
  }

  private getSalesRepresentativesByClient() {
    this.isLoadingSalesRep = true;
    this.templateService
      .getSalesRepresentativesByClient(this.data.sdrId)
      .pipe(finalize(() => (this.isLoadingSalesRep = false)))
      .subscribe({
        next: (salesReps) => {
          if (this.data?.source === Source.SendMessage) {
            this.allSalesRepObj = [...salesReps.filter((x) => x.geographicZone === 'LinkedIn')];
          } else {
            this.allSalesRepObj = [...salesReps];
          }

          this.salesRepFilterControl.addValidators((): ValidationErrors | null => {
            return !this.getSaleRepProfileCC() ? { invalidSalesRep: true } : null;
          });

          this.filteredSalesRepOptions = this.salesRepFilterControl.valueChanges.pipe(
            startWith(''),
            map((value) => (typeof value === 'string' ? value : value?.name)),
            map((name) => (name ? this._filterSalesRep(name) : this.allSalesRepObj?.slice()?.sort(this.sortSalesRep))),
          );
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting the sales representatives');
          this.allSalesRepObj = [];
        },
      });
  }

  private getSalesRepConnected() {
    this.isLoadingSalesRepConnected = true;
    this.sdrConversationService
      .getSalesRepConnected(this.data?.sdrId)
      .pipe(finalize(() => (this.isLoadingSalesRepConnected = false)))
      .subscribe({
        next: (salesRepConnected) => {
          if (!salesRepConnected || !salesRepConnected?.length) {
            this.snackBarService.showWarning('No sales representatives connected to this SDR');
            return;
          }
          this.allSalesRepConnected = [...salesRepConnected];
          this.filteredSalesRepConnectedOptions = this.salesRepFilterControl.valueChanges.pipe(
            startWith(''),
            map((value) => (typeof value === 'string' ? value : value?.name)),
            map((name) =>
              name ? this._filterSalesRepConnected(name) : this.allSalesRepConnected?.slice()?.sort(this.sortSalesRep),
            ),
          );
        },
        error: () => {
          this.snackBarService.showError('An error occurred while getting the sales representatives connected');
          this.allSalesRepConnected = [];
        },
      });
  }

  private getSaleRepProfileCC(): SalesRepresentative | null {
    if (!this.salesRepFilterControl.value) return;
    return this.allSalesRepObj.find((salesRep) => salesRep.name === this.salesRepFilterControl.value);
  }

  private getSaleRepConnected(): SalesRepresentativeConnected | null {
    if (!this.salesRepFilterControl.value) return;
    return this.allSalesRepConnected.find((salesRep) => salesRep.name === this.salesRepFilterControl.value);
  }

  private _filterSalesRep(salesRep: string): SalesRepresentative[] {
    const filterValue = salesRep.toLowerCase();
    return this.allSalesRepObj
      .filter((salesRep) => salesRep?.name?.toLowerCase().includes(filterValue))
      .sort(this.sortSalesRep);
  }

  private _filterSalesRepConnected(salesRep: string): SalesRepresentativeConnected[] {
    const filterValue = salesRep.toLowerCase();
    return this.allSalesRepConnected
      .filter((salesRep) => salesRep?.name?.toLowerCase().includes(filterValue))
      .sort(this.sortSalesRep);
  }

  private sortSalesRep(a, b) {
    const name1 = a.name.toLowerCase().trim();
    const name2 = b.name.toLowerCase().trim();
    if (name1 > name2) {
      return 1;
    }
    if (name1 < name2) {
      return -1;
    }
    return 0;
  }

  private async handleClearSalesRepresentativeCtrl() {
    this.salesRepFilterControl.clearValidators();
    this.salesRepFilterControl.updateValueAndValidity();
    this.salesRepFilterControl.reset();
  }
}
