import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { FeedService } from '../../services/feed.service';
import { SDAuthService } from '../../services/sd-auth.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
})
export class TopNavigationComponent implements OnInit {
  options = ['1', '2', '3', '4'];
  color = '#15fa86';
  userEmail = '';
  isAdmin = false;
  showFeed = true;
  hasDiscrepanciesPermission = false;
  hasSdrConversationsPermission = false;

  constructor(
    private authService: SDAuthService,
    private userService: UserService,
    private router: Router,
    private feedService: FeedService,
  ) {}

  ngOnInit() {
    this.authService.getCurrentUserEmail().then((email) => {
      this.userEmail = email;
    });
    this.userService.isUserAdmin().subscribe((isAdmin) => {
      this.isAdmin = isAdmin;
    });
    this.feedService.showFeed.subscribe((showFeed) => {
      this.showFeed = showFeed;
    });

    forkJoin({
      discrepancies: this.userService.hasPermission('discrepancies'),
      sdrMessages: this.userService.hasPermission('sdr_messages'),
    }).subscribe((permissions) => {
      this.hasDiscrepanciesPermission = permissions.discrepancies;
      this.hasSdrConversationsPermission = permissions.sdrMessages;
    });
  }

  logOut() {
    this.authService.logOut();
  }

  toggleFeed() {
    this.showFeed = !this.showFeed;
    this.feedService.setShowFeed(this.showFeed);
  }

  navigateToHome() {
    this.router.navigate(['/home']);
  }

  navigateToManagement() {
    this.router.navigate(['/home/management']);
  }

  navigateToMailReview() {
    if (this.router.url !== '/home/mail-review') window.open(`${window.location.origin}/home/mail-review`, '_blank');
  }

  navigateToMailManual() {
    if (this.router.url !== '/home/mail-manual') window.open(`${window.location.origin}/home/mail-manual`, '_blank');
  }

  navigateToConversations() {
    if (this.router.url !== '/home/sdr-conversations')
      window.open(`${window.location.origin}/home/sdr-conversations`, '_blank');
  }
}
