// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  isProd: false,
  apiBaseURL: 'https://api-dev.windifferent.com',
  emailApiBaseURL: 'https://email-api-op-eks.sd-dev.bairesdev.net/v1',
  s3BaseURL: 'https://sd-om-attachments.s3.amazonaws.com/',
  notificationsSocketURL: 'wss://3n6v844ke3.execute-api.us-west-2.amazonaws.com/main',
  slackBotURL: 'https://sfla7gd2p2.execute-api.us-west-2.amazonaws.com/Prod/slack',
  authUserPoolId: 'us-west-2_2aZ9Z01QU',
  authUserPoolWebClientId: '3078h1f2qkjpqj82eh14a7mni9',
  authDomain: 'operations-module-dev.auth.us-west-2.amazoncognito.com',
  newApiBaseUrl: 'https://api-operations.dev.wd-research.net/api/v1',
};
